import React from "react";
import { MCheckbox, MLabel, MSelect, ValidatedForm } from "components/forms";
import {
    Col, Row
} from "design-react-kit";
import {
    enrollmentAdminService, enrollmentService, networkErrorHelper, payloadBuilder
} from "libs";
import ReactHtmlParser from 'react-html-parser';
import {
    DichiarazioniUlteriori, FormTitleA, FormTitleAConfigurator,
    FormTitleB, FormTitleBConfigurator
} from "./enroll30CFU";

export class EnrollFillForm30CFU extends ValidatedForm {

    emptyFields = {
        examSite: 0,
    };

    state = {
        _formRefTitleA: null,
        _formRefTitleB: null,
        _formRefOther: null,
        loading: true,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        },
        titleA: "",
        titleBActive: [],
        validationSummary: ''
    };

    PAYLOADS = {
        examSites: [],
        titleSelect: [],
    }

    FIELDS_GROUP = [
        [{
            field: "preferExamPresence",
            label: "1. SEDE ESAMI",
            infoText: "Seleziona una sede convenzionata o in fase di accreditamento",
            component: MLabel,
            className: "col-md-6 pt-3"
        }],
        [{
            field: "examSite",
            payload: { options: this.PAYLOADS.examSites },
            component: MSelect,
            className: "col-md-6"
        }],
        [{
            field: "preferExamPresence",
            label: "2. REQUISITO DI ACCESSO",
            infoText: "(Riservato per coloro che sono già in possesso di abilitazione su una classe di concorso o su un altro grado di istruzione nonché per coloro che sono in possesso della specializzazione sul sostegno, che conseguono l'abilitazione in altre classi di concorso o in altri gradi di istruzione fermo restando il possesso del titolo di studio necessario con riferimento alla classe di concorso.):",
            component: MLabel,
            className: "col-md-12 pt-3"
        }],
        [{
            field: "titleSelect",
            payload: { options: this.PAYLOADS.titleSelect },
            component: MSelect,
            onChange: (_, value) => this.onChangeTitleA(_, value),
            className: "col-md-12"
        }]
    ];

    ERROR_MESSAGES = {
        examSite: 'Specificare una sede esami',
        titleSelect: 'Specificare un requisito di accesso'
    };

    validation = {
        examSite: value => value > '',
        titleSelect: value => value > ''
    };

    constructor(props) {

        super(props);
        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    getPayload = () => {
        return this.payload;
    }

    loadRemote(additionalState = {}) {

        const { idExamSession, courseCode } = this.props.enrollData;

        if (this.PAYLOADS.titleSelect.length < 1) {
            // load paylods to fill the select options
            this.PAYLOADS.titleSelect.unshift({ label: "SELEZIONARE UN REQUISITO DI ACCESSO", value: "" });
        };

        FormTitleAConfigurator.forms.forEach((item) => {
            this.PAYLOADS.titleSelect.push({ label: item.label, value: item.template });
        });

        this.setState({
            titleSelect: { options: this.PAYLOADS.titleSelect }
        });

        if (this.PAYLOADS.examSites.length < 1) {
            // load paylods to fill the select options 
            enrollmentAdminService.examSites(idExamSession, courseCode)
                .then(({ data }) => {

                    const { payload } = data;
                    const sites = payload.map(({ value }) => ({ label: value, value: value }));

                    this.PAYLOADS.examSites.unshift({ label: 'Seleziona', value: '' });
                    this.PAYLOADS.examSites.push(...sites);

                    this.setState({
                        examSites: { options: this.PAYLOADS.examSites }
                    });
                })
                .catch(errors => {
                    console.log(errors);
                    networkErrorHelper.notify(errors);
                });
        }

        // prepare refs to form
        const formRefTitleB = {
            'LaureaTriennale': React.createRef(),
            'LaureaMagistrale02': React.createRef(),
            'LaureaMagistraleCU': React.createRef(),
            'LaureaSpecialistica02': React.createRef(),
            'LaureaSpecialisticaCU': React.createRef(),
            'LaureaVecchioOrdinamento': React.createRef(),
            'AltroTitolo': React.createRef(),
        }

        const newState = {
            loading: false,
            ...additionalState,
            _formRefTitleA: React.createRef(),
            _formRefOther: React.createRef(),
            _formRefTitleB: formRefTitleB
        };

        this.setState(newState);
    }

    onSubmit = () => {

        let validationSummary = '';
        let isValid = false;
        let isValidTitleA = true;
        let isValidTitleB = true;
        let isValidOther = true;
        const { _formRefTitleA, _formRefOther, _formRefTitleB, titleBActive } = this.state;

        this.payload = payloadBuilder(this.state);
        let qualifications = [];

        let isValidForm = this.checkValidation()
        if (!isValidForm) {
            validationSummary += '<li>Inserire i campi obbligatori in rosso</li>';
        }

        // validate FormTitleA
        if (_formRefTitleA && (_formRefTitleA.current != null)) {
            isValidTitleA = _formRefTitleA.current.onSubmit();

            // get payload
            const payloadTitleA = _formRefTitleA.current.getPayload();

            if (isValidTitleA) {

                payloadTitleA.qualificationCode = 'titleA';
                qualifications.push(payloadTitleA);
            } else {
                validationSummary += '<li>Requisito di accesso seguente con dati mancanti <b>' + payloadTitleA.qualificationType + '</b></li>';
            }
        } else {
            isValidTitleA = false;
            validationSummary += '<li>Errore nell\'inserimento del requisito di accesso</li>';
        }

        // validate FormTitleB   
        if (titleBActive.length > 0) {

            titleBActive.forEach(element => {

                const form = _formRefTitleB[element];
                if (form && (form.current !== null)) {
                    isValidTitleB = form.current.onSubmit();
                    const payloadTitleB = form.current.getPayload();
                    if (isValidTitleB) {
                        // get payload                       
                        payloadTitleB.qualificationCode = 'titleB';
                        qualifications.push(payloadTitleB);
                    } else {
                        validationSummary += '<li>Titolo di studio seguente con dati mancanti <b>' + payloadTitleB.qualificationType + '</b></li>';
                    }
                } else {
                    validationSummary += '<li>Errore nell\'inserimento del titolo di studio</li>';
                    isValidTitleB = false;
                }
            });

            qualifications.forEach(title => {

                if (title.template === 'LaureaTriennale' && qualifications.length == 1) {
                    isValidTitleB = false;
                    validationSummary += '<li>Il solo titolo di studio <b>Laurea Triennale</b> non è sufficiente. Aggiungere altro titolo di studio oltre la <b>Laurea Triennale</b>.</li>';
                }
            });
        } else {
            isValidTitleB = false;
            validationSummary += '<li>Inserire almeno un titolo di studio</li>';
        }

        //  validate formOther
        if (_formRefOther && (_formRefOther.current != null)) {
            isValidOther = _formRefOther.current.onSubmit();
            if (isValidOther) {
                // get payload
                const payloadOther = _formRefOther.current.getPayload();
                qualifications.push(...payloadOther);
            } else {
                validationSummary += '<li>PUNTO 4. Ulteriori dichiarazioni con dati mancanti</li>';
            }
        } else {
            isValidOther = false;
            validationSummary += '<li>Errore imprevisto nelle dichiarazioni</li>';
        }

        // if valid call saveRemote: send data to server
        isValid = isValidTitleA && isValidTitleB && isValidOther && isValidForm;

        if (isValid) {
            const payloadQualification = {
                idEnrollment: this.props.enrollData.idEnrollment,
                qualifications: qualifications
            };

            //  invia al server (array di userEnrollmentQualification)
            function myFunction() {
                return new Promise((resolve, reject) => {
                    enrollmentService.updateEnrollmentQualification(payloadQualification)
                        .then(() => {
                            resolve();
                        })
                        .catch(errors => {
                            validationSummary += '<li>Errore imprevisto nell\'invio dei dati. Se il problema persiste contattare il supporto tecnico.</li>';
                            networkErrorHelper.notify(errors);
                            isValid = false;
                        });
                });
            }

            // Call the function and await the result
            myFunction()
                .then(() => {
                    // Handle success
                })
                .catch(errors => {
                    validationSummary += '<li>Errore imprevisto nell\'invio dei dati. Se il problema persiste contattare il supporto tecnico.</li>';
                    networkErrorHelper.notify(errors);
                    isValid = false;
                });
        }

        // set state with validation summary
        if (validationSummary !== '') {
            validationSummary = '<ul>' + validationSummary + '</ul>';
        }
        this.setState({ validationSummary: validationSummary });

        return isValid;
    }

    onChangeTitleA = (id, value) => {
        this.setState({ titleA: value });
    };

    onChangeTitleBActive = (id, value) => {

        const { titleBActive } = this.state;
        if (value) {
            if (!titleBActive.includes(id)) {
                titleBActive.push(id);
            }
        } else {
            const index = titleBActive.indexOf(id);
            if (index > -1) {
                titleBActive.splice(index, 1);
            }
        }

        this.setState({ titleBActive: titleBActive });
    }

    render() {

        const {
            loading, defaultValues, _formRefTitleA, _formRefTitleB,
            _formRefOther, validationSummary } = this.state;

        if (loading) return (<></>);        
        const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

        return (
            <>
                <div><form>{code}</form></div>
                <Row>
                    <Col>
                        {(FormTitleAConfigurator.forms || [])
                            .filter((item) => {
                                return item.template === this.state.titleA;
                            })
                            .map((item, idx) => {
                                //const Component = item.form;

                                return (
                                    <div key={`ctitleA=${item.template}`}>
                                        <p><b>{item.label}:</b> inserisci i dati</p>
                                        <FormTitleA
                                            key={`ftitleA=${item.template}`}
                                            qualificationType={item.label}
                                            template={item.template}
                                            ref={_formRefTitleA}
                                        />
                                    </div>
                                );
                            })}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <h6>3. TITOLO DI STUDIO</h6>
                        <p><b>Clicca sul titolo di studio e Inserisci i dati</b><br />
                            (si ricorda che la laurea deve avere tutti i CFU per l'accesso ad una delle classi di concorso (previste da Bando di Partecipazione) di cui alle Tabelle del D.P.R. 19/2016 come integrate/aggiornate dalle Tabelle del D.M. 259/2017, Tabella A del D.M. 221/2023 e DM 22/12/2023 GU n. 34 del 10/02/2024 Tabella A e Tabella A1):</p>
                        {(FormTitleBConfigurator.forms || []).map((item, idx) => {

                            return (
                                <div key={`titleB=${item.template}`}>
                                    <MCheckbox onChange={this.onChangeTitleBActive}
                                        id={item.template} name={item.template} readOnly={false} disabled={false} >
                                        <p><b> {item.label}</b></p>
                                    </MCheckbox>
                                    {this.state.titleBActive.includes(item.template) &&
                                        (<div className="m-4 p-3 border border-secondary rounded-lg">
                                            <FormTitleB
                                                key={`titleB=${item.template}`}
                                                qualificationType={item.label}
                                                template={item.template}
                                                ref={_formRefTitleB[item.template]}
                                            /></div>
                                        )}
                                </div>
                            );
                        })}
                    </Col>
                </Row>
                <hr />
                <Row className="mt-2">
                    <Col>
                        <h6>4. ULTERIORI DICHIARAZIONI PER LA CLASSE DI CONCORSO {this.props.enrollData.pluginConfig.competitionClass} </h6>
                        <DichiarazioniUlteriori ref={_formRefOther} competitionClass={this.props.enrollData.pluginConfig.competitionClass} />
                    </Col>
                </Row>
                <Row>
                    <div className="w-100">
                        {validationSummary &&
                            <div className="alert alert-danger mt-3" role="alert">{ReactHtmlParser(validationSummary)}</div>}
                    </div>
                </Row>
            </>
        );
    }
}
