import { ROUTES } from "const";

export class RCCFUALL {


  static async getDashboardCards() {
    return this.getFixedCards();
  }

  static getFixedCards() {

    return [
      {
        title: "Messaggi",
        link: ROUTES.COURSE_TICKETS,
        img: "/img/icon/message.png",
        className: "image-box",
      },
      {
        title: "Situazione pagamenti",
        link: ROUTES.COURSE_PAYMENTS,
        img: "/img/icon/payment.png",
        className: "image-box",
      },
      {
        title: "Lezioni telematiche in modalità sincrona",
        link: ROUTES.COURSE_LESSONLIVE_SCHEDULE,
        img: "/img/icon/presentation.png",
        className: "image-box",
      },
      
    ];
  }

  static getDashboardNotices(courseCode) {

    let notices = [/*
      {
        content: `<p>La sua domanda di ammissione alla selezione per l'accesso ai Percorsi universitari di Formazione Iniziale e Abilitazione dei docenti 
          della scuola secondaria di I e di II grado ai sensi del D.P.C.M. 4 agosto 2023 è stata acquisita dal sistema. 
          La invitiamo a rimanere in attesa di successive comunicazioni.</p>
          La Direzione`,
      },*/
    ];

    switch (courseCode) {

      case 'RC30CFUALL2':
        const n30 = this.getRC30Notices();
        notices.push(...n30);
        break;

      case 'RC60CFUALL1':
        const n60 = this.getRC60Notices();
        notices.push(...n60);
        break;

      default:
        break;
    }

    return notices;
  }

  static getRC60Notices() {

    return [
      {
        content: `<p>Calendario delle Attività formative in modalità telematica sincrona inerenti i 4 CFU dell'Area Trasversale del 
        Percorso di formazione iniziale Abilitante di 60 CFU (ai sensi dell'art. 2 bis del decreto legislativo n. 59 del 2017 e all'art. 7, comma 2
         del DPCM 4 agosto 2023  ALL. 1).  
        <a href="https://www.formazionedocenti.it/files/corsi/RCALL/calendario-lezioni-online-60cfu-all1.pdf" target="_blank">Clicca qui per scaricare il calendario</a></p>`,
      },
      {
        content: `<p>Calendario delle Attività formative in presenza inerenti i 20 CFU dell'Area Trasversale del Percorso di formazione iniziale 
        Abilitante di 60 CFU (ai sensi dell'art. 2 bis del decreto legislativo n. 59 del 2017 e all'art. 7, comma 2 del DPCM 4 agosto 2023  ALL. 1). 
        <a href="#">A breve disponibile</a></p>`,
      },
      {
        content: `<p>Calendario delle Attività formative in modalità telematica sincrona inerenti i 16 CFU dell'Area Specifica per 
        ciascuna classe di concorso del Percorso di formazione iniziale Abilitante di 60 CFU (ai sensi dell'art. 2 bis del decreto
         legislativo n. 59 del 2017 e all'art. 7, comma 2 del DPCM 4 agosto 2023  ALL. 1). 
        <a href="#">A breve disponibile</a></p>`,
      },
      {
        content: `<p>Linee guida per lo svolgimento delle lezioni in modalità telematica sincrona. 
        <a href="https://www.formazionedocenti.it/files/corsi/RCALL/linee-guida-lezioni.pdf" target="_blank">Clicca qui per scaricare le linee guida</a></p>`,
      },
    ];
  }

  static getRC30Notices() {

    return [
      {
        content: `<p>Calendario delle Attività formative in modalità telematica sincrona inerenti i 5 CFU 
        dell'Area Trasversale del Percorso di formazione iniziale Abilitante di 30 CFU (ai sensi dell'art. 2-ter, comma 4-bis e dell'art. 13, 
        comma 2, del decreto legislativo n. 59 del 2017 e dell'art. 7, comma 6, del DPCM 4 agosto 2023 ALL. 2).
        <a href="https://www.formazionedocenti.it/files/corsi/RCALL/calendario-lezioni-online-rc30-all2.pdf" target="_blank">Clicca qui per scaricare il calendario</a></p>`,
      },
      {
        content: `<p>Calendario delle Attività formative in presenza inerenti i 12 CFU dell'Area Trasversale del Percorso 
        di formazione iniziale Abilitante di 30 CFU (ai sensi dell'art. 2-ter, comma 4-bis e dell'art. 13, comma 2, 
        del decreto legislativo n. 59 del 2017 e dell'art. 7, comma 6, del DPCM 4 agosto 2023 ALL. 2). 
        <a href="#">A breve disponibile</a></p>`,
      },
      {
        content: `<p>Calendario delle Attività formative in modalità telematica sincrona inerenti i 4 CFU dell'Area Specifica per ciascuna
         classe di concorso del Percorso di formazione iniziale Abilitante di 30 CFU (ai sensi dell'art. 2-ter, comma 4-bis e dell'art. 13, comma 2, 
         del decreto legislativo n. 59 del 2017 e dell'art. 7, comma 6, del DPCM 4 agosto 2023 ALL. 2). 
        <a href="#">A breve disponibile</a></p>`,
      },
      {
        content: `<p>Linee guida per lo svolgimento delle lezioni in modalità telematica sincrona. 
        <a href="https://www.formazionedocenti.it/files/corsi/RCALL/linee-guida-lezioni.pdf" target="_blank">Clicca qui per scaricare le linee guida</a></p>`,
      },
    ];
  }
}
