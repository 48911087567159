import React, { Component } from "react";

import { NavLink } from "react-router-dom";

import {
  Header,
  HeaderContent,
  HeaderToggler,
  Collapse,
  Nav,
  NavItem,
  Icon,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  LinkList,
} from "design-react-kit";

import { ROUTES } from "../../const";

export class NHAdmin extends Component {
  state = {
    collapsed: true,
  };

  manageEvtByLink = (link) => {
    this.setState({ collapsed: true });
  };

  //--- ---

  render() {
    const isOpen = !this.state.collapsed;

    return (
      <>
        <Header small theme="" type="navbar">
          <HeaderContent expand="lg" megamenu>
            <HeaderToggler
              aria-controls="nav1"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => this.setState({ collapsed: isOpen })}>
              <Icon icon="it-burger" />
            </HeaderToggler>
            <Collapse
              isOpen={isOpen}
              navbar
              header
              onOverlayClick={() => this.setState({ collapsed: true })}>
              <div className="menu-wrapper mnemo-dark">
                <Nav navbar tag="ul" vertical={false}>
                  <NavItem
                    key={`menu-item-close`}
                    tag="li"
                    onClick={() => this.setState({ collapsed: isOpen })}>
                    <div className="mnemo-close-li">
                      CHIUDI <Icon icon="it-close-circle" padding={false} />
                    </div>
                  </NavItem>

                  <NavItem tag="li">
                    <UncontrolledDropdown nav tag="div">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          aria-haspopup
                          caret
                          color="secondary"
                          nav>
                          Amministrazione
                        </DropdownToggle>
                        <DropdownMenu
                          flip
                          ag="div"
                          className="dropdown-menu-show-fix mnemo-dark">
                          <LinkList tag="div">
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.CONTACT_REQUEST}
                                ag="a">
                                Lista adesioni
                              </NavLink>
                            </NavItem>
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.PROMOTIONAL_MANAGER}
                                ag="a">
                                Promozioni
                              </NavLink>
                            </NavItem>

                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.SITECONFIG_MANAGER}
                                ag="a">
                                Configurazione
                              </NavLink>
                            </NavItem>
                          </LinkList>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </UncontrolledDropdown>
                  </NavItem>

                  <NavItem tag="li">
                    <UncontrolledDropdown nav tag="div">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          aria-haspopup
                          caret
                          color="secondary"
                          nav>
                          Area Revisore
                        </DropdownToggle>
                        <DropdownMenu
                          flip
                          ag="div"
                          className="dropdown-menu-show-fix mnemo-dark">
                          <LinkList tag="div">
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.CURRENT_REVISION}
                                ag="a">
                                Revisioni Correnti
                              </NavLink>
                            </NavItem>

                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.HISTORY_REVISION}
                                ag="a">
                                Storico Revisioni
                              </NavLink>
                            </NavItem>
                          </LinkList>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </UncontrolledDropdown>
                  </NavItem>

                  <NavItem tag="li">
                    <UncontrolledDropdown nav tag="div">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          aria-haspopup
                          caret
                          color="secondary"
                          nav>
                          Gestione Utenti
                        </DropdownToggle>
                        <DropdownMenu
                          flip
                          ag="div"
                          className="dropdown-menu-show-fix mnemo-dark">
                          <LinkList tag="div">
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={`${ROUTES.USER_LIST}/admin`}
                                tag="a">
                                Amministratori
                              </NavLink>
                            </NavItem>
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={`${ROUTES.USER_LIST}/student`}
                                tag="a">
                                Studenti
                              </NavLink>
                            </NavItem>
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={`${ROUTES.USER_LIST}/segreteria`}
                                tag="a">
                                Segreteria
                              </NavLink>
                            </NavItem>
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={`${ROUTES.USER_LIST}/editor`}
                                tag="a">
                                Editor
                              </NavLink>
                            </NavItem>
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={`${ROUTES.USER_SYNC_IN}`}
                                tag="a">
                                Sincronizza BD.BE
                              </NavLink>
                            </NavItem>
                          </LinkList>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </UncontrolledDropdown>
                  </NavItem>

                  <NavItem tag="li">
                    <UncontrolledDropdown nav tag="div">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          aria-haspopup
                          caret
                          color="secondary"
                          nav>
                          Gestione Corsi
                        </DropdownToggle>
                        <DropdownMenu
                          flip
                          ag="div"
                          className="dropdown-menu-show-fix mnemo-dark">
                          <LinkList tag="div">
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.COURSE_MANAGER}
                                ag="a">
                                Elenco Corsi
                              </NavLink>
                            </NavItem>

                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.COURSE_TYPE_MANAGER}
                                ag="a">
                                Tipo Corsi
                              </NavLink>
                            </NavItem>

                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.COURSE_REPOQUIZ_MANAGER}
                                ag="a">
                                Deposito delle Domande
                              </NavLink>
                            </NavItem>

                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.LESSON_LIVE_SCHEDULE}
                                ag="a">
                                Calendario Lezioni Sincrone
                              </NavLink>
                            </NavItem>
                          </LinkList>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </UncontrolledDropdown>
                  </NavItem>

                  <NavItem tag="li">
                    <UncontrolledDropdown nav tag="div">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          aria-haspopup
                          caret
                          color="secondary"
                          nav>
                          CMS
                        </DropdownToggle>
                        <DropdownMenu
                          flip
                          ag="div"
                          className="dropdown-menu-show-fix mnemo-dark">
                          <LinkList tag="div">
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.BLOG_CATEGORY_MANAGER}
                                ag="a">
                                Articoli
                              </NavLink>
                            </NavItem>
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.PAGE_MANAGER}
                                ag="a">
                                Pagine
                              </NavLink>
                            </NavItem>
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.SURVEY_MANAGER}
                                ag="a">
                                Questionari
                              </NavLink>
                            </NavItem>
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.LIBRARY_CATEGORY_MANAGER}
                                ag="a">
                                Biblioteca
                              </NavLink>
                            </NavItem>
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.QUALIFICATION_DATA_ANS_MANAGER}
                                ag="a">
                                Gestione Dati ANS
                              </NavLink>
                            </NavItem>
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.ASSETS_AUDIO_CATEGORY_MANAGER}
                                ag="a">
                                Assets Audio
                              </NavLink>
                            </NavItem>
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.ACTIONS_OVER_DB}
                                ag="a">
                                Azioni su DB
                              </NavLink>
                            </NavItem>
                          </LinkList>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </UncontrolledDropdown>
                  </NavItem>

                  <NavItem tag="li" onClick={this.props.logout}>
                    <NavLink className="nav-link" to="/" ag="a">
                      Esci
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Collapse>
          </HeaderContent>
        </Header>
      </>
    );
  }
}
