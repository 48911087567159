import React from "react";
import { MSection, Title } from "components/misc";
import { Row, Col } from "design-react-kit";
import { Helmet } from "react-helmet";
import { pageService, payloadBuilder } from "libs";

import {
  FormEnabler,
  ValidatedForm,
  MInput,
  MCheckbox,
} from "components/forms";

export class Form30CfuUnicamillusView extends ValidatedForm {

  constructor(props) {
    super(props);
    this.state = {
      ...this.emptyFields,
      defaultValues: { ...this.emptyFields },
      formActive: true,
      isSuccess: 0,
    };
  }

  emptyFields = {
    name: "",
    surname: "",
    email: "",
    mobile: "",
    accept: false,
    type: "30cfuunicamillus",
  };

  FIELDS_GROUP = [
    [
      {
        id: "name",
        field: "name",
        label: "Nome",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
      {
        id: "surname",
        field: "surname",
        label: "Cognome",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
    [
      {
        id: "email",
        field: "email",
        label: "Email",
        component: MInput,
        type: "email",
        className: "col-md-6",
      },
      {
        id: "mobile",
        field: "mobile",
        label: "Cellulare",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
    [
      {
        id: "accept",
        field: "accept",
        label:
          "Dichiaro di aver letto la Privacy Policy ai sensi del Regolamento UE 2016/679 (GDPR)",
        component: MCheckbox,
        className: "col-md-12",
      },
    ],
  ];

  ERROR_MESSAGES = {
    name: "Inserisci il tuo nome",
    surname: "Inserisci il tuo cognome",
    email: "Inserisci la tua email",
    accept: "Accetta la privacy policy per continuare",
    mobile: "Inserisci il tuo numero di telefono",
  };

  validation = {
    name: (value) => value !== "",
    surname: (value) => value !== "",
    email: (value) => /\S+@\S+\.\S+/.test(value),
    accept: (value) => value !== false,
    mobile: (value) => value !== "",
  };

  saveRemote = () => {
    let payload = payloadBuilder(this.state);

    pageService
      .saveContactRequest(payload)
      .then((result) => {
        this.setState({ isSuccess: 200 });
        window.location.href = "https://www.unicamillus.org/it/30-cfu-ex-art-13-dpcm/";
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          this.setState({ isSuccess: 422 });
        }
      });
  };

  onCancel = () => {
    this.resetForm();
  };

  render() {
    let { formActive, defaultValues } = this.state;
    const { formValid, code } = this.renderFields(
      this.FIELDS_GROUP,
      defaultValues,
      formActive
    );

    const title = "Corsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)";


    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </Helmet>
        <MSection>
          <Row>
            <Col>
              {/*   <div className="text-center">
                <Title>{title}</Title>                
              </div>
              <p>Università UniCamillus (Saint Camillus International University of Health Sciences)</p>
            <ul>
              <li>A028	Matematica e Scienze </li>
              <li>A050	Scienze naturali, chimiche e biologiche</li>
              <li>A031	Scienze degli alimenti</li>
              <li>B012	Laboratori di scienze e tecnologie chimiche e microbiologiche</li>
            </ul>
            <hr/>
            <h4 className="text-danger">RICHIEDI MAGGIORI INFORMAZIONI</h4>
              <form className="user" id="30cfuform">
                {code}
              </form>
              <div>
                <p className="form-check-label mt-2">
                  Leggi la{" "}
                  <a
                    href="/page/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy
                  </a>
                </p>{" "}
              </div>
              <div className="mt-2">
                <FormEnabler
                  onSubmit={this.onSubmit}
                  buttonText="Clicca qui per iscriverti"
                  onToggle={this.toggleForm}
                  isFormActive={formActive}
                  disableSave={!formValid}
                />
    </div>*/}
            </Col>
          </Row>
        </MSection>
      </>
    );
  }
}