import React, { Component } from 'react';
import { formHelper } from 'libs/helpers';
import './MTextArea.css';

export class MTextArea extends Component {

    /**
     * PROPS: 
     * id       : identifier 
     * label    : example text (brief description of fields)
     * value    : the value
     * infoText : addtional text used when the input in invalid
     * disabled : insert for readonly 
     * readOnly : is readOnly
     * rows     : number of row
     * valid    : set  when the input is valid
     * invalid  : set  when the input is invalid
     */
    render() {

        // get properties
        const { onChange, id, name,
            disabled, readOnly,
            rows, label, valid,
            invalid, infoText, value } = this.props;

        // map properites
        const txtProps = {
            id, name,
            readOnly: disabled || readOnly,
            rows,
            value
        };

        let className = (valid) ? 'is-valid' : '';
        className += (invalid) ? 'is-invalid' : '';

        const onChangeFunction = formHelper.isFunction(onChange) ? onChange : formHelper.emptyFunction;

        /**  TODO: verificare come poter mettere placeholder se il testo è vuoto
         * e trasformarlo in label quando il componente è attivo 
         * o il componente contiene del testo
         */
        return (
            <>
                <label htmlFor={txtProps.id} >{label}</label>
                <textarea
                    className={className}                   
                    {...txtProps}
                    onChange={event => onChangeFunction(txtProps.name, event.target.value)}
                >                   
                </textarea>
                {invalid && (<label className="text-danger" htmlFor={txtProps.id} >
                    {infoText}
                </label>)}
            </>
        );
    }
}