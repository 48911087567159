import { proxyBE } from './common';

export const revisionService = {

    listQuizOpenAll(isRevisioned, idUser) {
        return proxyBE.get(`admin/quizreview/listAll/${isRevisioned}/${idUser}`);
    },

    getQuizReview(payload) {      
        return proxyBE.get(`admin/quizreview/${JSON.stringify(payload)}`);        
    },

    updateQuizReview(payload) {      
        return proxyBE.put(`admin/quizreview`, payload);        
    },

    updateQuizReviewFeedback(payload) {      
        return proxyBE.put(`admin/quizreview-feedback`, payload);        
    },
}