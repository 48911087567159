import { SupportoTecnico } from "components/misc";
import { PAYMENT_STATUS_LABEL, ROUTES } from "const";
import { Button } from "design-react-kit";
import { dateHelper } from "libs";
import React from "react";

export class EnrollEnd {

    title = 'Fine';
    iconName = 'it-mail';    

    setPayload(payload) {
        this.enrollment = payload.enrollment;
    }

    async onNextStep (){                           
        return true;
    }

    goHome = () => {

        window.location = (ROUTES.DASHBOARD);
    }

    render() {

        const dateStart = dateHelper.toITDateTime(this.enrollment.dateStart);
        const dateSign = dateHelper.toITDateTime(this.enrollment.dateSign);
        const dateEnd = dateHelper.toITDateTime(this.enrollment.dateEnd);
        const paymentStatus = PAYMENT_STATUS_LABEL[this.enrollment.paymentStatus];
        return (<>
            <p>
                Domanda di iscrizione completata e correttamente inviata. Ti abbiamo inviato una email con i documenti della domanda.
                Appena avremo riscontro del pagamento il corso sarà disponibile presso la tua ara riservata e sarai avvisato con una email.</p>
            <ul>
                <li><b>Numero:</b> {this.enrollment.orderNumber}</li>
                <li><b>Data avvio procedura:</b> {dateStart}</li>
                <li><b>Stato della domanda di iscrizione:</b> attesa conferma da segreteria</li>
                <li><b>Modalità di pagamento: </b> {this.enrollment.paymentType}</li>
                <li><b>Importo: </b> Euro {this.enrollment.importToPay}</li>
                <li><b>Stato del pagamento:</b>  {paymentStatus}</li>
                <li><b>firma domanda: </b> {dateSign}</li>
                <li><b>data fine procedura: </b> {dateEnd}</li>
            </ul>
            <p>Per ogni domanda puoi <SupportoTecnico /></p>
            <div className="text-right" >
                <Button color="primary bg-dark" onClick={this.goHome}>Vai alla tua Area riservata</Button>
            </div>
        </>);
    }
}