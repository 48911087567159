import React from "react";

import {
  FormEnabler,
  ValidatedForm,
  MInput,
  MTextArea,
  MSelect,
  MToggle,
  MFile,
  MSearch,
  MLabel,
} from "components/forms";

import {
  payloadBuilder,
  invalidFieldsMapper,
  networkErrorHelper,
  notNullObjectBuilder,
  adminService,
} from "libs";

import dayjs from "dayjs";

import { Card, CardBody } from "design-react-kit";
import { BoxInfo } from "components/misc";

export class AdminLessonLiveScheduleForm extends ValidatedForm {
  ERROR_MESSAGES = {
    title: "Titolo obbligatorio",
    maxMember: "Max Partecipanti obbligatorio",
    date: "Data obbligatoria",
    hourStart: "Ora inizio obbligatoria",
    aa: "Anno Accademico obbligatorio (AAAA)",
    idClass: "Classe obbligatoria",
    idExamSession: "ID Sessione Esame obbligatorio (intero)",
    //competitionClass: "Classe di Concorso obbligatoria",
  };

  emptyFields = {
    title: "",
    description: "",
    idMeet: "",
    maxMember: "",
    date: "",
    hourStart: "",
    hourEnd: "",
    teacher: "",
    aa: "",
    idClass: "-1",
    idExamSession: "",
    ssd: "",
    cfu: "",
    visible: false,
    enableAttendance: false,
    competitionClass: "-1",
    trainingStart: "",
    trainingEnd: "",
    document:
      parseInt(this.props.idLessonLiveSchedule) > 0
        ? parseInt(this.props.idLessonLiveSchedule)
        : 0,
  };

  validation = {
    title: (value) => value.length > 0,
    date: (value) => /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(value),
    hourStart: (value) => /^([0-9]{2}):([0-9]{2})$/.test(value),
    idClass: (value) => value !== "-1",
    aa: (value) => /^\d{4}$/i.test(value) && value !== null,
    maxMember: (value) => !isNaN(parseInt(value)),
    idExamSession: (value) => /^[0-9]+$/i.test(value),
    //competitionClass: (value) => value !== "-1",
    hourEnd: (value) => {
      if (value !== "" && value !== null) {
        return /^([0-9]{2}):([0-9]{2})$/.test(value);
      }
      return true;
    },
    trainingStart: (value) => {
      if (value !== "" && value !== null) {
        return /^([0-9]{2}):([0-9]{2})$/.test(value);
      }
      return true;
    },
    trainingEnd: (value) => {
      if (value !== "" && value !== null) {
        return /^([0-9]{2}):([0-9]{2})$/.test(value);
      }
      return true;
    },
  };

  arrClasses = [{ label: "Seleziona Classe", value: "-1" }];
  arrIDClasses = [{ label: "Seleziona Classe", value: "-1" }];
  arrIdExamSession = [{ label: "Seleziona Sessione Esame", value: "-1" }];

  PAYLOAD_COMPETITIONCLASS = [
    { label: "Seleziona Classe di Concorso", value: "-1" },
    { label: "in Comune", value: "common" },
    { label: "A011-Discipline Letterarie e Latino", value: "A011" },
    {
      label:
        "A012-Discipline Letterarie negli Istituti di Istruzione Secondaria di II Grado",
      value: "A012",
    },
    { label: "A019-Filosofia e Storia ", value: "A019" },
    {
      label:
        "A022-Italiano, Storia, Geografia nella Scuola Secondaria di I Grado",
      value: "A022",
    },
    { label: "A045-Scienze Economico-Aziendali", value: "A045" },
    { label: "A046-Scienze Giuridico-Economiche", value: "A046" },
    {
      label:
        "A048-Scienze Motorie e Sportive negli Istituti di Istruzione Secondaria di II Grado",
      value: "A048",
    },
    {
      label:
        "A049-Scienze Motorie e Sportive nella Scuola Secondaria di I Grado",
      value: "A049",
    },
    { label: "A054-Storia dell'Arte", value: "A054" },
    {
      label:
        "AB24-Lingue e Culture Straniere negli Istituti di Istruzione di II Grado (Inglese)",
      value: "AB24",
    },
    {
      label:
        "AB25-Lingua Inglese e Seconda Lingua Comunitaria nella Scuola Secondaria I Grado (Inglese)",
      value: "AB25",
    },
  ];

  FIELDS_GROUP = [
    [
      {
        id: "visible",
        name: "visible",
        field: "visible",
        label: "Visibile",
        component: MToggle,
        className: "col-md-3",
      },
      {
        id: "enableAttendance",
        name: "enableAttendance",
        field: "enableAttendance",
        label: "Abilita attestato",
        component: MToggle,
        className: "col-md-3",
      },
    ],
    [
      {
        id: "title",
        field: "title",
        label: "Titolo",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
      {
        id: "description",
        field: "description",
        label: "Descrizione",
        component: MTextArea,
        rows: 3,
        className: "col-md-12 mb-4",
      },
    ],
    [
      {
        id: "idMeet",
        field: "idMeet",
        label: "Meeting ID",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
      {
        id: "maxMember",
        field: "maxMember",
        label: "Max Partecipanti",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
    [
      {
        id: "date",
        field: "date",
        label: "Data",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
      {
        id: "hourStart",
        field: "hourStart",
        label: "Ora inizio",
        component: MInput,
        type: "text",
        infoText: "ora di inizio nel formato HH:mm",
        className: "col-md-4",
      },
      {
        id: "hourEnd",
        field: "hourEnd",
        label: "Ora fine",
        component: MInput,
        type: "text",
        infoText: "ora di fine nel formato HH:mm",
        className: "col-md-4",
      },
    ],
    [
      {
        field: "idClass",
        label: "IDClasse",
        payload: { options: this.arrIDClasses },
        component: MSelect,
        className: "col-md-5",
      },
      {
        id: "teacher",
        field: "teacher",
        label: "Insegnanti",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
      {
        id: "aa",
        field: "aa",
        label: "Anno accademico",
        component: MInput,
        type: "text",
        className: "col-md-3",
      },
    ],
    [
      {
        id: "idExamSession",
        field: "idExamSession",
        label: "ID SEssione Esame",
        payload: { options: this.arrIdExamSession },
        component: MSelect,
        className: "col-md-5",
      },
      {
        id: "ssd",
        field: "ssd",
        label: "SSD",
        component: MInput,
        type: "text",
        className: "col-md-5",
      },
      {
        id: "cfu",
        field: "cfu",
        label: "CFU",
        component: MInput,
        type: "text",
        className: "col-md-2",
      },
    ],    
    [
      {
        id: "trainingStart",
        field: "trainingStart",
        label: "Ora inizio esercitazione",
        component: MInput,
        type: "text",
        infoText: "ora di inizio nel formato HH:mm",
        className: "col-md-6",
      },
      {
        id: "trainingEnd",
        field: "trainingEnd",
        label: "Ora fine esercitazione",
        component: MInput,
        type: "text",
        infoText: "ora di fine nel formato HH:mm",
        className: "col-md-6",
      },
    ],
    [
      {
          field: "trainingLabel",
          label: "Materiale da utilizzare durante l'esercitazione",          
          component: MLabel,
          className: "col-md-12 pt-3"
      }
    ],
    [
      {
        id: "document",
        field: "document",
        label: "allegati",
        component: MFile,
        config: {
          endPoint: "/admin/lessonliveschedule-file-training",
          mandatory: false,
          accept: '.pdf',
          fieldName: "document",
          maxFiles: 6,
        },
        className: "col-md-12",
      },
    ],
    [
      {
          field: "slideLabel",
          label: "Slides/Materiale didattico consultabile in sola lettura",          
          component: MLabel,
          className: "col-md-12 pt-3"
      }
    ],
    [
      {
        id: "document",
        field: "document",
        label: "allegati",
        component: MFile,
        config: {
          endPoint: "/admin/lessonliveschedule-file-slide",
          mandatory: false,
          accept: '.pdf',
          fieldName: "document",
          maxFiles: 10,
        },
        className: "col-md-12",
      },
    ],
  ];

  //--- ---

  constructor(props) {
    super(props);

    this.state = {
      ...this.emptyFields,
      defaultValues: { ...this.emptyFields },
      formActive: true,
      idLessonLiveSchedule: this.props.idLessonLiveSchedule,
      rndKey: 0,
      currentCompetitionClass: [],
      selectedCompetitionClass: [],
      defaultOptionValues: [],
    };

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.loadRemote();
    this.loadRemoteOption();
    this.loadRemoteOptionExamSession();
  }

  loadRemote(additionalState = {}) {
    //--- prevent loadRemote when not required ---
    if (parseInt(this.props.idLessonLiveSchedule) <= 0) {
      return false;
    }

    let filter = {
      searchFilter: {
        id: parseInt(this.props.idLessonLiveSchedule),
      },
    };

    adminService
      .loadLessonLiveSchedule(filter)
      .then(({ data }) => {
        //--- force date format to date ---
        data.payload.lessonLiveSchedule.date = dayjs(
          data.payload.lessonLiveSchedule.date
        ).format("YYYY-MM-DD");

        data.payload.lessonLiveSchedule.hourStart =
          data.payload.lessonLiveSchedule.hourStart.slice(0, -3);
        data.payload.lessonLiveSchedule.hourEnd =
          data.payload.lessonLiveSchedule.hourEnd.slice(0, -3);

        data.payload.lessonLiveSchedule.trainingStart =
          data.payload.lessonLiveSchedule.trainingStart !== null
            ? data.payload.lessonLiveSchedule.trainingStart.slice(0, -3)
            : null;
        data.payload.lessonLiveSchedule.trainingEnd =
          data.payload.lessonLiveSchedule.trainingEnd !== null
            ? data.payload.lessonLiveSchedule.trainingEnd.slice(0, -3)
            : null;

        //--- prepare competitionClass for form ---
        if(data.payload.lessonLiveSchedule.competitionClass !== ''){
          for ( let i = 0; i < data.payload.lessonLiveSchedule.competitionClass.split(",").length; i++ ) {
            this.state.defaultOptionValues.push({
              label: data.payload.lessonLiveSchedule.competitionClass.split(",")[i],
              value: data.payload.lessonLiveSchedule.competitionClass.split(",")[i],
            });
          }
        } 

        const defaultValues = notNullObjectBuilder(
          this.emptyFields,
          data.payload.lessonLiveSchedule,
          {
            document: parseInt(data.payload.lessonLiveSchedule.id),
          }
        );

        const newState = {
          defaultValues,
          ...additionalState,
          loading: false,
          rndKey: Math.floor(Math.random() * 1000 + 1),
        };

        this.setState(newState);
      })
      .catch((error) => {
        networkErrorHelper.notify(error);
        console.log(error);
        this.setState({ loading: false });
      });
  } //loadremote

  loadRemoteOption() {
    if (this.arrClasses.length === 1) {
      let filter = {
        searchFilter: {
          id: undefined,
        },
      };

      adminService
        .loadLessonLiveClass(filter)
        .then(({ data }) => {
          //--- start: prepare reactTable ---
          Object.entries(data.payload.lessonLiveClass).forEach(([key, val]) => {
            this.arrClasses.push({ label: val.label, value: val.label });
            this.arrIDClasses.push({ label: val.label, value: val.id });
          });
          //--- end: prepare reactTable ---

          //this.arrClasses.reverse();
          //this.arrIDClasses.reverse();

          this.setState({
            loading: false,
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    }
  } //loadremoteOption

  loadRemoteOptionExamSession() {
    if (this.arrIdExamSession.length === 1) {
      adminService
        .loadLessonLiveEditionExamSession()
        .then(({ data }) => {
          Object.entries(data.payload.lessonLiveEditionExamSession).forEach(
            ([key, val]) => {
              this.arrIdExamSession.push({
                label: val.title,
                value: val.idExamSession,
              });
            }
          );

          this.setState({
            loading: false,
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    }
  } //loadremoteOption

  onCancel = () => {
    this.resetForm();
  }; //onCancel

  onBackPage = () => {
    window.history.back();
  };

  saveRemote = () => {
   
    if (
      this.state.currentCompetitionClass.length === 0 &&
      this.state.defaultOptionValues.length === 0
    ) {
      return;
    }

    const payload = payloadBuilder(this.state);
    payload.competitionClass = this.state.currentCompetitionClass.join(",") || this.state.defaultOptionValues.map(option => option.value).join(",");

    if (parseInt(this.props.idLessonLiveSchedule) > 0) {
      adminService
        .updateLessonLiveSchedule(payload)
        .then((result) => {
          this.onBackPage();
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              this.ERROR_MESSAGES
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    } else {
      adminService
        .insertLessonLiveSchedule(payload)
        .then((result) => {
          this.onBackPage();
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              this.ERROR_MESSAGES
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    }
  };

  changeCompetitionClass = (i, value) => {
    let currentCompetitionClass = [];
    if (value && value.length > 0) {
      currentCompetitionClass = value.map((item, i) => {
        return item.value;
      });
    }

    this.setState({ currentCompetitionClass, selectedCompetitionClass: value });
  }; //changeCompetitionClass

  //--- ---

  render() {
    if (parseInt(this.props.idLessonLiveSchedule) <= 0) {
      this.FIELDS_GROUP = this.FIELDS_GROUP.map((group) =>
        group.filter((element) => element.id !== "document")
      );
    }

    let {
      formActive,
      defaultValues,
      idLessonLiveSchedule,
      rndKey,
      selectedCompetitionClass,
      defaultOptionValues,
      currentCompetitionClass,
    } = this.state;

    const { formValid, code } = this.renderFields(
      this.FIELDS_GROUP,
      defaultValues,
      formActive
    );

    return (
      <>
        <Card
          tag="div"
          className="card-bg card-big rounded shadow border-bottom-card">
          <div className="flag-icon" />
          <CardBody tag="div">
            <MSearch
              key={rndKey}
              field="competitionClass-select"
              id="competitionClass-select"
              name="-select"
              placeHolder="Inserisci o seleziona Classe di Concorso"
              payload={this.PAYLOAD_COMPETITIONCLASS}
              isMulti="true"
              selectedOption={selectedCompetitionClass}
              onChange={this.changeCompetitionClass}
              infoText={"Devi selezionare almeno una Classe di Concorso"}
              defaultOptionValues={defaultOptionValues}
            />

            {currentCompetitionClass.length === 0 &&
              defaultOptionValues.length === 0 && (
                <BoxInfo className="mt-3">
                  Devi selezionare almeno una categoria.
                </BoxInfo>
              )}

            <form className="user" id="promotionalform">
              {code}
            </form>

            <div className="mt-4">
              <FormEnabler
                onSubmit={this.onSubmit}
                onCancel={
                  parseInt(idLessonLiveSchedule) > 0
                    ? this.onCancel
                    : this.onBackPage
                }
                onToggle={this.toggleForm}
                isFormActive={formActive}
                disableSave={!formValid}
              />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
