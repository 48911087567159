import React, { Component } from "react";

import { NoticeBoardStudent, NoticeBoardAdmin } from "components/dashboard";

import { getCurrentUser } from "store/actions";
import { MnemoLoading, MSection, Title } from "components/misc";
import {
  Container,
  Row,
  Icon,
  Card,
  CardBody,
  CardCategory,
  NavItem,
  LinkList,
  Navbar,
  Badge
} from "design-react-kit";

import { NavLink } from "react-router-dom";
import { COURSE_STATUS, ROUTES } from "const";
import { learningService, networkErrorHelper } from "libs";

export class Dashboard extends Component {
  state = {
    loading: true,
    myCourses: [],
    myFreeCourses: [],
    navExpanded: false,
  };

  componentDidMount() {
    const user = getCurrentUser();
    if (user.roles === "student") {
      this.loadRemote();
    } else {
      this.setState({ loading: false });
    }
  }

  loadRemote() {
    learningService
      .myCourses()
      .then(({ data }) => {
        this.setState({ myCourses: data.payload.courses, loading: false });
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          console.log(error);
          networkErrorHelper.notify(error);
        }

        this.setState({ loading: false });
      });

    learningService
      .myFreeCourses()
      .then(({ data }) => {
        this.setState({ myFreeCourses: data.payload.courses, loading: false });
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          console.log(error);
          networkErrorHelper.notify(error);
        }

        this.setState({ loading: false });
      });
  }

  render() {
    const user = getCurrentUser();
    const { loading, myCourses, myFreeCourses } = this.state;

    return (
      <>
        {loading ? (
          <MnemoLoading></MnemoLoading>
        ) : (
          <section className="my-4">
            <Container>
              {user.roles === "student" && (
                <>
                  <NoticeBoardStudent />
                  <div className="section section-background-header p-3">
                    <Container>
                      <Row>
                        <h2 className="text-white p-3">Le tue attività</h2>
                      </Row>
                      <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal card-teaser-block-3">
                        <Card
                          teaser
                          noWrapper
                          className="no-after rounded shadow"
                          key="it-user"
                        >
                          <CardBody>
                            <CardCategory>
                              <Icon icon="it-user" />
                            </CardCategory>
                            <div className="navbar-wrapper">
                              <Navbar
                                className="it-navscroll-wrapper"
                                expand="lg"
                                tag="nav"
                              >
                                <LinkList
                                  tag="div"
                                  wrapperClassName="menu-link-list"
                                  header={<h4 className="no_toc">PROFILO</h4>}
                                >
                                  <NavItem key="user1" tag="li">
                                    <NavLink
                                      className="nav-link text-dark"
                                      to={ROUTES.MY_PROFILE}
                                    >
                                      I miei dati
                                    </NavLink>
                                  </NavItem>
                                  <NavItem key="user2" tag="li">
                                    <NavLink
                                      className="nav-link text-dark"
                                      to={ROUTES.MY_QUALIFICATION}
                                    >
                                      Titoli di studio
                                    </NavLink>
                                  </NavItem>
                                  <NavItem key="user3" tag="li">
                                    <NavLink
                                      className="nav-link text-dark"
                                      to={ROUTES.MY_INVOICEDATA}
                                    >
                                      Dati di fatturazione
                                    </NavLink>
                                  </NavItem>
                                  <NavItem key="user4" tag="li">
                                    <NavLink
                                      className="nav-link text-dark"
                                      to={ROUTES.MY_DOCUMENT}
                                    >
                                      Documento di riconoscimento
                                    </NavLink>
                                  </NavItem>
                                  <NavItem key="user5" tag="li">
                                    <NavLink
                                      className="nav-link text-dark"
                                      to={ROUTES.PASSWORD_RESET_UNTOKEN}
                                    >
                                      Cambio Password
                                    </NavLink>
                                  </NavItem>
                                </LinkList>
                              </Navbar>
                            </div>
                          </CardBody>
                        </Card>
                        <Card
                          teaser
                          noWrapper
                          className="no-after rounded shadow"
                          key="it-presentation"
                        >
                          <CardBody>
                            <CardCategory>
                              <Icon icon="it-presentation" />
                            </CardCategory>
                            <div className="navbar-wrapper">
                              <Navbar
                                className="it-navscroll-wrapper"
                                expand="lg"
                                tag="nav"
                              >
                                <LinkList
                                  tag="div"
                                  wrapperClassName="menu-link-list"
                                  header={
                                    <h4 className="no_toc">I MIEI CORSI ATTIVI</h4>
                                  }
                                >
                                  {myCourses.length > 0 ? (
                                    myCourses.filter((item) => item.status === COURSE_STATUS.ACTIVE).map((course, j) => {
                                      const link = `${ROUTES.COURSE_DASHBOARD}/${course.registrationCode}`;
                                      return (
                                        <NavItem
                                          tag="li"
                                          key={`courselink-${j}`}
                                        >
                                          <NavLink
                                            className="nav-link"
                                            to={link}
                                          >
                                            {course.code} <Badge pill className="badge-aa" color={course.aa < parseInt(process.env.REACT_APP_AA_YEAR) ? "primary" : "success"}>A.A. {course.aa}/{course.aa+1}</Badge> 
                                          </NavLink>
                                        </NavItem>
                                      );
                                    })
                                  ) : (
                                    <>
                                      Non risultano corsi attivi.
                                      <br />
                                      <a href={ROUTES.COURSE_BROWSE}>
                                        Consulta i nostri corsi
                                      </a>
                                    </>
                                  )}
                                </LinkList>
                                
                              </Navbar>
                            </div>
                            <div className="navbar-wrapper">
                              <Navbar
                                className="it-navscroll-wrapper"
                                expand="lg"
                                tag="nav"
                              >
                                <LinkList
                                  tag="div"
                                  wrapperClassName="menu-link-list"
                                  header={
                                    <h4 className="no_toc">I MIEI CORSI COMPLETATI</h4>
                                  }
                                >
                                  {myCourses.length > 0 && myCourses.filter(item => item.status === "completed").length > 0 ? (
                                    
                                    myCourses.filter((item) => item.status === "completed").map((course, j) => {
                                      const link = `${ROUTES.COURSE_DASHBOARD}/${course.registrationCode}`;
                                      return (
                                        <NavItem
                                          tag="li"
                                          key={`courselink-${j}`}
                                        >
                                          <NavLink
                                            className="nav-link"
                                            to={link}
                                          >
                                            {course.code} <Badge pill className="badge-aa" color="secondary">A.A. {course.aa}/{course.aa+1}</Badge> 
                                          </NavLink>
                                        </NavItem>
                                      );
                                    })
                                  ) : (
                                    <>
                                      Non risultano corsi completati.
                                      {/* <br />
                                      <a href={ROUTES.COURSE_BROWSE}>
                                        Consulta i nostri corsi
                                      </a> */}
                                    </>
                                  )}
                                </LinkList>
                                
                              </Navbar>
                            </div>
                            {myFreeCourses.length > 0 && (
                              <>
                                <div className="navbar-wrapper">
                                  <Navbar
                                    className="it-navscroll-wrapper"
                                    expand="lg"
                                    tag="nav"
                                  >
                                    <LinkList
                                      tag="div"
                                      wrapperClassName="menu-link-list"
                                      header={
                                        <h4 className="no_toc">
                                          CORSI ADDIZIONALI
                                        </h4>
                                      }
                                    >
                                      {myFreeCourses.filter((item) => item.status === COURSE_STATUS.ACTIVE).map((course, j) => {
                                        const link = `${ROUTES.LEARNING_MAIN}/${course.registrationCode}`;
                                        return (
                                          <NavItem
                                            tag="li"
                                            key={`courselink-${j}`}
                                          >
                                            <NavLink
                                              className="nav-link"
                                              to={link}
                                            >
                                              {course.code}
                                            </NavLink>
                                          </NavItem>
                                        );
                                      })}
                                    </LinkList>
                                  </Navbar>
                                </div>
                              </>
                            )}
                          </CardBody>
                        </Card>
                        <Card
                          teaser
                          noWrapper
                          className="no-after rounded shadow"
                          key="it-pin"
                        >
                          <CardBody>
                            <CardCategory>
                              <Icon icon="it-pin" />
                            </CardCategory>

                            <div className="navbar-wrapper">
                              <Navbar
                                className="it-navscroll-wrapper"
                                expand="lg"
                                tag="nav"
                              >
                                <LinkList
                                  tag="div"
                                  wrapperClassName="menu-link-list"
                                  header={<h4 className="no_toc">UTILITA'</h4>}
                                >
                                  { /* 
                                  Eliminato da saverio poichè c'è un bug
                                  <NavItem key="pin1" tag="li">
                                    <NavLink
                                      className="nav-link text-dark"
                                      to={ROUTES.COURSE_PAYMENTS}
                                    >
                                      Situazione pagamenti
                                    </NavLink>
                                  </NavItem>
                                 
                                  <NavItem key="pin2" tag="li">
                                    <NavLink
                                      className="nav-link text-dark"
                                      to={ROUTES.COURSE_INVOICES}
                                    >
                                      Le mie fatture
                                    </NavLink>
                                  </NavItem>
                                   */}
                                  <NavItem key="pin3" tag="li">
                                    <NavLink
                                      className="nav-link text-dark"
                                      to={ROUTES.COURSE_TICKETS}
                                    >
                                      Richiesta assistenza
                                    </NavLink>
                                  </NavItem>
                                </LinkList>
                              </Navbar>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Container>
                  </div>
                </>
              )}

              {user.roles === "admin" && (
                <>
                  <Title>Amministrazione del sito</Title>
                  <NoticeBoardAdmin />
                  <MSection>
                    <h6>Link utili</h6>
                    <hr />
                    <NavLink to={ROUTES.EXAMPLES}>Esempi & Prove</NavLink>
                  </MSection>
                </>
              )}

              {user.roles === "segreteria" && (
                <>
                  <Title>Segreteria del sito</Title>
                  <NoticeBoardAdmin />
                  <MSection></MSection>
                </>
              )}
            </Container>
          </section>
        )}
      </>
    );
  }
}
