import React from "react";

import {
    MSelect, MInput,
    ValidatedForm,
    MFile,
} from "components/forms";

import { PAYMENT_TYPES } from 'const';
import { BoxDanger, BoxInfo, SupportoTecnico } from "components/misc";
import { Icon } from "design-react-kit";

import {
    payloadBuilder,
    notNullObjectBuilder,
    CourseHelper
} from "libs";

export class EnrollPayForm extends ValidatedForm {

    PAYLOADS = {
        paymentTypes: [
            { value: '', label: 'Metodo di pagamento' },
            { value: PAYMENT_TYPES.CARTA_DI_CREDITO, label: 'Carta di credito' },
            { value: PAYMENT_TYPES.BONIFICO, label: 'Bonifico' },
            { value: PAYMENT_TYPES.CARTA_DEL_DOCENTE, label: 'Carta del docente' }
        ],
        paymentTypesUnicamillus: [
            { value: '', label: 'Metodo di pagamento' },
            { value: PAYMENT_TYPES.CARTA_DEL_DOCENTE, label: 'Carta del docente' },
            { value: PAYMENT_TYPES.PAGOPA, label: 'PagoPA (bonifico, carta di credito, bollettino)' }
        ],
        paymentTypesReggio: [
            { value: "", label: "Metodo di pagamento" },
            {
                value: PAYMENT_TYPES.MAV,
                label: "MAV",
            },
        ],
        paymentTypesReggioBonifico: [
            { value: "", label: "Metodo di pagamento" },
            {
                value: PAYMENT_TYPES.BONIFICO,
                label: "BONIFICO",
            },
        ],
    }

    onChangePaymentType = (_, value) => {
        this.resetFields();
        this.setState({ paymentSelected: value });
    }

    FIELDS_GROUP = [
        [
            {
                field: "paymentType",
                label: "Metodo di pagamento",
                payload: { options: this.PAYLOADS.paymentTypes },
                component: MSelect,
                readOnly: true,
                className: "col-md-6",
                onChange: this.onChangePaymentType
            },
            {
                field: "importToPay",
                label: "Importo",
                component: MInput,
                className: "col-md-6",
                readOnly: true
            }
        ]
    ];

    FIELDS_BONIFICO = [
        [
            {
                id: "datePayment",
                field: "datePayment",
                label: "Data Bonifico",
                component: MInput,
                type: 'date',
                className: "col-md-6",
            },
            {
                field: "paymentBancSource",
                label: "Intestatario conto",
                component: MInput,
                type: 'text',
                className: "col-md-6",
            }
        ],
        [{
            id: "document",
            field: "document",
            label: "Ricevuta di pagamento",
            infoText: "Caricare la ricevuta del bonifico",
            component: MFile,
            config: { endPoint: '/enrollment/payment-file', mandatory: true, fieldName: 'document', maxFiles: 1 },
            className: "col-md-12"
        }]
    ];

    FIELDS_MAV = [
        [{
            id: "datePayment",
            field: "datePayment",
            label: "Data Pagamento",
            component: MInput,
            type: 'date',
            className: "col-md-6",
        }, {
            id: "document",
            field: "document",
            label: "Ricevuta di pagamento",
            infoText: "Caricare la ricevuta del MAV pagato",
            component: MFile,
            config: { endPoint: '/enrollment/payment-file', mandatory: true, fieldName: 'document', maxFiles: 1 },
            className: "col-md-12"
        }]
    ];

    FIELDS_CARTA_DOCENTE = [
        [{
            id: "document",
            field: "document",
            label: "Voucher carta del docente",
            infoText: "Caricare il voucher carta del docente",
            component: MFile,
            config: { endPoint: '/enrollment/payment-file', mandatory: true, fieldName: 'document', maxFiles: 1 },
            className: "col-md-12"
        }]
    ];

    ERROR_MESSAGES = {
        paymentType: "Specificare la modalità di pagamento",
        paymentBancSource: "Specificare l'intestatario del conto (cognome e nome)",
        //  document: 'Caricare un file ',
        datePayment: "Specificare la data del pagamento"
    };

    validation = {
    };

    validation_default = {
        paymentType: value => value > '',
    };

    validation_carta_credito = {
        paymentType: value => value > '',
    };

    validation_pagopa = {
        paymentType: value => value > '',
        //    document: value => this.validateDocument(value),
    };

    validation_carta_docente = {
        paymentType: value => value > '',
        //    document: value => this.validateDocument(value),
    };

    validation_bonifico = {
        paymentType: value => value > '',
        datePayment: value => /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(value),
        //    document: value => this.validateDocument(value),
        paymentBancSource: value => value.length > 4,
    };

    validation_mav = {
        paymentType: value => value > '',
        datePayment: value => /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(value),
    };

    emptyFields = {
        importToPay: 0,
        paymentType: '',
        paymentBancSource: '',
        document: 0,
        datePayment: ''
    };

    state = {
        paymentSelected: '',
        loading: true,
        loadingError: false,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        }
    };

    constructor(props) {
        super(props);

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    getPayload = () => {
        return this.payload;
    }

    loadRemote(additionalState = {}) {

        // merge not null values and format dates
        const defaultValues = notNullObjectBuilder(
            this.emptyFields,
            this.props.enrollData,
        );

        const newState = {
            defaultValues,
            paymentSelected: defaultValues.paymentType,
            loading: false,
            ...additionalState,
        };

        this.setState(newState);
    }

    saveRemote = () => {

        this.payload = payloadBuilder(this.state);

        const validations = {
            importToPay: null,
            paymentType: null,
        }

        this.setState({ formActive: false, ...validations });
    };

    renderPaymentInfo = () => {

        const { paymentSelected, defaultValues } = this.state;
        let codeInfo = <></>;
        let codeForm = <></>;
        let renderInfo;

        let cause = 'Iscrizione Corso ' + this.props.enrollData.courseCode + ' - Cod.Fiscale ' + this.props.enrollData.fiscalCode;

        if (CourseHelper.isUnicamillus(this.props.enrollData.courseCode)) {
            this.FIELDS_GROUP[0][0].payload = { options: this.PAYLOADS.paymentTypesUnicamillus }
        } else if (CourseHelper.isReggioBonifico(this.props.enrollData.courseCode)) {
            this.FIELDS_GROUP[0][0].payload = { options: this.PAYLOADS.paymentTypesReggioBonifico }
            this.FIELDS_GROUP[0][0].readOnly = false;
        } else if (CourseHelper.isReggio(this.props.enrollData.courseCode)) {
            this.FIELDS_GROUP[0][0].payload = { options: this.PAYLOADS.paymentTypesReggio }
            this.FIELDS_GROUP[0][0].readOnly = false;
        } else {
            this.FIELDS_GROUP[0][0].payload = { options: this.PAYLOADS.paymentTypes }
            this.FIELDS_GROUP[0][0].readOnly = false;
        }

        switch (paymentSelected) {
            case PAYMENT_TYPES.BONIFICO:

                //this.resetFields();
                this.validation = this.validation_bonifico;
                renderInfo = this.renderFields([...this.FIELDS_GROUP, ...this.FIELDS_BONIFICO], defaultValues, true);
                codeForm = renderInfo.code;

                if (CourseHelper.isTFASoprannumerariReggio(this.props.enrollData.courseCode)) {
                    cause = this.props.enrollData.fiscalCode + ' Ammissione TFA Sostegno Soprannumerari/Idonei';
                }
                else if (CourseHelper.isTFAReggio(this.props.enrollData.courseCode)) {
                    cause = this.props.enrollData.fiscalCode + ' Ammissione TFA Sostegno Riservato ';
                } else if (CourseHelper.isTFAOrdinarioReggio(this.props.enrollData.courseCode)) {
                    cause = this.props.enrollData.fiscalCode + ' Ammissione TFA Sostegno Ordinario ';
                } else if (CourseHelper.isReggioBonifico(this.props.enrollData.courseCode)) {
                    if (this.props.enrollData.courseCode === 'RC60CFUALL1' || this.props.enrollData.courseCode === 'RC30CFUALL2') {
                        cause = this.props.enrollData.fiscalCode + ' - ammissione corso ' + this.props.enrollData.courseCode + ' - ' + (this.props.enrollData.isPaymentReferred ? 'prima rata' : 'soluzione unica');
                    } else {
                        cause = this.props.enrollData.fiscalCode + ' - iscrizione corso ' + this.props.enrollData.courseCode + ' - ' + (this.props.enrollData.isPaymentReferred ? 'prima rata' : 'soluzione unica');
                    }
                }

                codeInfo = CourseHelper.isReggioBonifico(this.props.enrollData.courseCode) ?
                    <>
                        <div>
                            <p>Eseguire il pagamento tramite Bonifico Bancario, utilizzare i segueti dati:</p>
                            <Icon icon="it-check" /> Intestato a: <b>Università per Stranieri Dante Alighieri</b><br />
                            <Icon icon="it-check" /> Causale: <b>{cause}<span className="importDescriptionCausale"></span></b><br />
                            <Icon icon="it-check" /> Banca: <b> Monte dei Paschi di Siena</b><br />
                            <Icon icon="it-check" /> IBAN: <b> IT 10 L 0103016300 000002535012 </b><br />
                            <Icon icon="it-check" /> Bic code - Swift:<b> PASCITMMREG </b><br />
                            <Icon icon="it-check" /> Importo:<b> euro  {this.props.enrollData.importToPay}</b><br />
                            <p>(NON SI ACCETTANO pagamenti tramite Bonifico Istantaneo)</p>
                        </div>
                    </> : <>
                        <div>
                            <p>Eseguire il pagamento tramite Bonifico Bancario o Bonifico Postale, utilizzare i segueti dati:</p>
                            <Icon icon="it-check" /> Intestato a: <b>Mnemosine</b><br />
                            <Icon icon="it-check" /> Causale: <b>{cause}<span className="importDescriptionCausale"></span></b><br />
                            <Icon icon="it-check" /> Banca: <b> BANCA INTESA SANPAOLO</b><br />
                            <Icon icon="it-check" /> IBAN: <b> IT34B0306983140100000001438 </b><br />
                            <Icon icon="it-check" /> Bic code - Swift:<b> BCITITMM</b><br />
                            <Icon icon="it-check" /> Importo:<b> euro  {this.props.enrollData.importToPay}</b><br />
                        </div>
                    </>;
                break;

            case PAYMENT_TYPES.CARTA_DEL_DOCENTE:

                //this.resetFields();
                this.validation = this.validation_carta_docente;
                renderInfo = this.renderFields([...this.FIELDS_GROUP, ...this.FIELDS_CARTA_DOCENTE], defaultValues, true);
                codeForm = renderInfo.code;
                if (CourseHelper.isUnicamillus(this.props.enrollData.courseCode)) {

                    if (this.props.enrollData.courseCode.startsWith('CSU') ||
                        this.props.enrollData.courseCode.startsWith('MUMC24')) {
                        codeInfo =
                            <>
                                <p>Per maggiori informazioni <a href="/files/corsi/U24CFU/Istruzioni_Cart_Docenti_U24CFU_Unicamillus.pdf" >vedi come generare il bonus per i 24 CFU Unicamillus</a></p>
                            </>;
                    } else if (this.props.enrollData.courseCode.startsWith('MU')) {
                        codeInfo =
                            <>
                                <p>Per maggiori informazioni <a href="/files/corsi/MU/Istruzioni_Cart_Docenti_Corsi_Unicamillus.pdf" >vedi come generare il bonus per i Master Unicamillus</a></p>
                            </>;
                    } else if (this.props.enrollData.courseCode.startsWith('MDU')) {
                        codeInfo =
                            <>
                                <p>Per maggiori informazioni <a href="/files/corsi/MDU/Istruzioni_Carta_Docenti_Corsi_MDU_Unicamillus.pdf" >vedi come generare il bonus per i Master di II Livello Unicamillus</a></p>
                            </>;
                    } else if (this.props.enrollData.courseCode.startsWith('BU')) {
                        codeInfo =
                            <>
                                <p>Per maggiori informazioni <a href="/files/corsi/BU-I/Istruzioni_Cart_Docenti_BU_Unicamillus.pdf" >vedi come generare il bonus per i Diplomi di Specializzazione Biennale Unicamillus</a></p>
                            </>;
                    } else if (this.props.enrollData.courseCode.startsWith('UM')) {
                        codeInfo =
                            <>
                                <p>Per maggiori informazioni <a href="/files/corsi/UM30/Istruzioni_Cart_Docenti_Unicamillus_30cfu.pdf" >vedi come generare il bonus per i Diplomi di Specializzazione Biennale Unicamillus</a></p>
                            </>;
                    } else {
                        codeInfo =
                            <>
                                <p>Per maggiori informazioni <a href="/files/corsi/MDU/Istruzioni_Cart_Docenti_Corsi_Unicamillus.pdf" >vedi come generare il bonus per i Corsi Unicamillus</a></p>
                            </>;
                    }
                } else {
                    codeInfo =
                        <>
                            <p>Per maggiori informazioni <a href="/come-generare-il-bonus-scuola-per-i-corsi-mnemosine/" target="_blank" rel="noopener noreferrer">vedi come generare il bonus per i corsi Mnemosine</a></p>
                        </>;
                }
                break;

            case PAYMENT_TYPES.CARTA_DI_CREDITO:

                //this.resetFields();
                this.validation = this.validation_carta_credito;
                renderInfo = this.renderFields(this.FIELDS_GROUP, defaultValues, true);
                codeForm = renderInfo.code;
                codeInfo =
                    <>
                        <p>Paga online al passo successivo con le seguenti carte di credito:</p>
                        <img title="Paga con Visa" alt="Visa" src="/img/logo_visa.png" />
                        <img title="Paga con MasterCard" alt="MasterCard" src="/img/logo_mastercard.png" />
                        <img title="Paga con Maestro" alt="Maestro" src="/img/logo_maestro.png" /><br />
                    </>;
                break;

            case PAYMENT_TYPES.PAGOPA:

                //this.resetFields();
                this.validation = this.validation_pagopa;
                renderInfo = this.renderFields(this.FIELDS_GROUP, defaultValues, true);
                codeForm = renderInfo.code;
                codeInfo =
                    <>
                        <p>Paga dopo il completamento della domanda tramite il sistema pagoPA, potrai pagare con carta di credito, bonifico, bollettino</p>
                        <img title="Paga con Visa" alt="Visa" src="/img/PagoPA.png" />
                    </>;
                break;

            case PAYMENT_TYPES.MAV:

                //this.resetFields();
                this.validation = this.validation_mav;
                renderInfo = this.renderFields([...this.FIELDS_GROUP, ...this.FIELDS_MAV], defaultValues, true);
                codeForm = renderInfo.code;
                codeInfo =
                    <>
                        <div>

                        </div>
                    </>;
                break;

            default:

                this.validation = this.validation_default;
                //this.resetFields();
                renderInfo = this.renderFields(this.FIELDS_GROUP, defaultValues, true);
                codeForm = renderInfo.code;
                break;
        }

        return (
            <>
                {(CourseHelper.isReggio(this.props.enrollData.courseCode)) ? <div><BoxInfo><b>Effettuata l'iscrizione online dovrà attendere che la segreteria le invii il MAV di € 66 (prima rata) tramite email.
                    Dalla ricezione del predetto MAV avrà a disposizione 2 giorni per procedere con il pagamento e caricare la ricevuta nel box sottostante.</b>
                </BoxInfo><br /></div> : ''}
                <div>{codeForm}</div>
                <div>{codeInfo}</div>
            </>);
    }

    render() {

        const { loading, loadingError } = this.state;
        if (loading) return <></>;
        if (loadingError) return <BoxDanger>{loadingError}, <SupportoTecnico /></BoxDanger>;

        return (<>
            <div>{this.renderPaymentInfo()}</div>
        </>);
    }
}