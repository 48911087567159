/**
 * Mostra tabella delle QuizTtemptReview revisionate
 */
/**
 * Mostra tabella delle QuizTtemptReview da revisionare
 */
import React, { Component } from "react";
import { MnemoLoading, BoxDanger, Title } from "components/misc";
import { networkErrorHelper, dateHelper } from "libs";

import { MTable } from "components/table";
import { Container } from "design-react-kit";

import { ROUTES } from "const";
import { revisionService } from "libs/api/revisionService";
import { SearchUser } from "components/misc/SearchUser";
import { withRouter } from "react-router-dom";

class ReviewHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rndKey: 0,
      payload: [],
      isRevisioned: true,
      idUser: this.props.match.params.idUser || 0,
    };
  }

  //--- ---

  tableConfig = {
    columns: [
      {
        id: "name",
        Header: "Nome",
        accessor: "name",
      },
      {
        id: "surname",
        Header: "Cognome",
        accessor: "surname",
      },
      {
        id: "courseCode",
        Header: "Codice Corso",
        accessor: "courseCode",
      },
      {
        id: "numberModule",
        Header: "Modulo",
        accessor: "numberModule",
      },
      {
        id: "dateEnd",
        Header: "Data Invio",
        accessor: (row) => {
          return row.dateEnd ? dateHelper.toITDate(row.dateEnd) : "";
        },
      },
      /*{
                id: 'revisionVote',
                Header: 'Voto',
                accessor: 'revisionVote',
            },*/
      {
        id: "revisionDate",
        Header: "Data Revisione",
        accessor: (row) => {
          return row.revisionDate ? dateHelper.toITDate(row.revisionDate) : "";
        },
      },
    ],
    actions: [
      {
        id: "detail",
        accessor: "id",
        label: "Dettagli",
        icon: "it-file",
        className: "MTable-actions",
        onClick: (e, value) => {
          window.location.href = ROUTES.QUIZREVIEW_FORM + "/" + value;
        },
      },
    ],
  };

  //--- ---

  componentDidMount() {
    if (parseInt(this.state.idUser) > 0) {
      this.loadRemote(this.state.isRevisioned, this.state.idUser);
    }
  }

  loadRemote(isRevisioned, idUser) {
    this.setState({ loading: true });

    revisionService
      .listQuizOpenAll(isRevisioned, idUser)
      .then(({ data }) => {
        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data.payload.quizreview).forEach(([key, val]) => {
          items.push(val);
        });

        this.setState({
          loading: false,
          payload: items,
          rndKey: Math.floor(Math.random() * 1000 + 1),
        });
        //--- end: prepare reactTable ---
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          console.log(error);
          networkErrorHelper.notify(error);
        } else {
          this.setState({
            payload: [],
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        }

        this.setState({ loading: false });
      });
  }

  onSearch = (keySearch) => {
    if (
      keySearch.length < 3 &&
      this.state.payloadToRender !== this.state.payload
    ) {
      this.setState({
        payloadToRender: [], //this.state.payload,
        rndKey: Math.floor(Math.random() * 1000 + 1),
      });
      return false;
    }

    if (keySearch.length < 3) return false;
    const results = this.state.payload.filter(function (item) {
      return (
        item.name.toLowerCase().includes(keySearch.toLowerCase()) ||
        item.surname.toLowerCase().includes(keySearch.toLowerCase()) ||
        item.email.toLowerCase().includes(keySearch.toLowerCase())
      );
    });

    this.setState({
      payloadToRender: results,
      rndKey: Math.floor(Math.random() * 1000 + 1),
    });
  }; //onSearch

  goToExt = (data) => {
    if (data?.userId && parseInt(data?.userId || 0) > 0) {
      window.location.href = ROUTES.HISTORY_REVISION + "/" + data.userId;
    }
  }; //goToExt

  //--- ---

  render() {
    const { loading, idUser } = this.state;

    return (
      <>
        <Title>Stotico Revisioni</Title>

        {parseInt(idUser) === 0 ? (
          <SearchUser role="student" goToExt={this.goToExt} />
        ) : (
          ""
        )}

        {this.state.idUser ? 
          (<>
            <hr />
            <section className="my-4">
              {loading ? (
                <MnemoLoading></MnemoLoading>
              ) : (
                <Container>
                  <MTable
                    key={this.state.rndKey}
                    tableData={this.state.payload}
                    tableConfig={this.tableConfig}
                  />
                </Container>
              )}

              {this.state.payload.length === 0 && (
                <BoxDanger key="nrp-1" className="my-3">
                  <div className="small text-info">
                    Non ci sono dati da mostrare in database.
                  </div>
                </BoxDanger>
              )}
            </section>
          </>) : ''
        }
      </>
    );
  }
}

export default withRouter(ReviewHistory);
