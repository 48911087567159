import { ROUTES } from "const";

export class CM30 {

  
  static async getDashboardCards(registrationCode) {

    return this.getFixedCards(registrationCode);
  }

  static getFixedCards() {

    return [
      {
        title: "Messaggi",
        link: ROUTES.COURSE_TICKETS,
        img: "/img/icon/message.png",
        className: "image-box",
      },
      {
        title: 'Situazione pagamenti', link: ROUTES.COURSE_PAYMENTS,
        img: '/img/icon/payment.png', className: 'image-box'
    },      
      {
        title: "Lezioni",
        link: ROUTES.COURSE_LESSONLIVE_SCHEDULE,
        img: "/img/icon/presentation.png",
        className: "image-box",
      },
    ];
  }

  static getDashboardNotices() {

    return [
      {
        title: ``,
        content: `<p>La ringraziamo per aver scelto di partecipare al Percorso 30 CFU/CFA (ex art.13 DPCM 4 agosto 2023) 
          riservato a chi è in possesso di una qualsiasi abilitazione all'insegnamento o specializzazione sul sostegno.
           Ricordiamo che le attività didattiche (in modalità sincrona) si svolgeranno nel prossimo Autunno e potrà liberamente 
           scegliere di frequentare le lezioni nei giorni infrasettimanali (orari pomeridiani) o nei weekend.<br/>
          L'esame finale del corso è previsto entro la fine del 2024 almeno nelle seguenti sedi:<br/>
          Agrigento, Ancona, Bari, Bologna, Cagliari, Caserta/Cassino, Catania, Catanzaro, Como, Cosenza, Firenze, Foggia, Genova, Grosseto, Lecce, Matera, Mazara del Vallo (TP), Messina, Milano, Napoli, Novara/Alessandria, Nuoro/Sassari, Padova/Venezia, Palermo, Perugia/Terni, Pescara, Pisa, Reggio di Calabria, Roma, Sciacca, Torino, Udine, Verona, Vibo Valentia e Vittoria (RG).
          <br/>Altre eventuali sedi saranno attivate successivamente.
        La invitiamo ad attendere nostre comunicazioni che verranno notificate sia tramite email che tramite sms dopo le vacanze estive.
        </p>`,
      },
    ];

  }
}
