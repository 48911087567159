import {proxyApiMnemo} from './common';
import { mnemoService } from 'libs/api';

export const enrollmentAdminService = {

    freeCourses(courseCode) {
        const payload = mnemoService.buildPayload('free_courses', 'courseCode', courseCode);
        return proxyApiMnemo.get('enrollment/?payload='+encodeURI(payload));
    },
   
    examSites(idExamSession, courseCode) {
        const payload = mnemoService.buildPayloadObject("exam_sites", {
            courseCode, idExamSession
          });
        return proxyApiMnemo.get('enrollment/?payload='+encodeURI(payload));        
    },

    learningSites(idExamSession, courseCode) {
        const payload = mnemoService.buildPayloadObject("learning_sites", {
            idExamSession: idExamSession,
            courseCode: courseCode,
          });
        return proxyApiMnemo.get('enrollment/?payload='+encodeURI(payload));        
    },

    importsToPay(courseCode) {
        const payload = mnemoService.buildPayload('imports_to_pay', 'courseCode', courseCode);
        return proxyApiMnemo.get('enrollment/?payload='+encodeURI(payload));        
    },

    examSessions(courseType) {
        const payload = mnemoService.buildPayload('exam_sessions', 'courseType', courseType);
        return proxyApiMnemo.get('enrollment/?payload='+encodeURI(payload));        
    },

    examSession(courseCode) {
        const payload = mnemoService.buildPayload('exam_session', 'courseCode', courseCode);
        return proxyApiMnemo.get('enrollment/?payload='+encodeURI(payload));        
    }
}