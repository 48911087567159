import React, { Component } from 'react';
import { formHelper } from 'libs';
import { Button } from 'design-react-kit';

/** load from local */
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';

export class MCE extends Component {

    onSaveContent = () => {

        if (formHelper.isFunction(this.props.onChange)) {          
            this.props.onChange(this.props.name, this.state.content);
        }

    }

    onEditorChange = (newText) => {

        this.setState({ content: newText });
    };

    render() {
       
        const {
            id, name, value,
            label, valid, config,
            invalid, infoText
        } = this.props;

        const readOnly = this.props.disabled || this.props.readOnly;

        let className = (valid) ? 'is-valid' : '';
        className += (invalid) ? 'is-invalid' : '';
        className += (readOnly) ? 'readOnly' : '';
    
        const myId = id + Math.floor((Math.random() * 1000) + 1);;

        const height= (config && config.height) ?  config.height : 600;

        return (
            <>
                <div >
                    <Editor className={className}
                        id={myId}
                        name={name}
                        initialValue={value}
                        apiKey="c4i03l2u4a8t3j013ag9lqbm1etxs8nhy6jglfmswrlzyg53"
                        disabled={readOnly}
                        onEditorChange={(newText) => this.onEditorChange(newText)}
                        init={{
                            height: height,
                            menubar: false,
                            paste_data_images: true,
                            plugins: [
                                'save advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'cancel | undo redo paste | formatselect | ' +
                                'bold italic underline backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | image  table media | fullscreen code preview |  ' +
                                'link unlink openlink | help',
                        }}
                    />
                    {!readOnly && (<>
                        <Button color="primary bg-dark mt-2" onClick={this.onSaveContent} >Applica Modifiche</Button></>)}
                    {invalid && (<label className="text-danger" htmlFor={myId} >
                        {infoText}
                    </label>)}

                </div>
            </>
        );
    }
}