import { ROUTES } from "const";
import { courseService } from "libs";

export class UM30 {

  // SAVERIO: PEZZA per mostrare avvisi differenti per edizioni differenti
  static secondEdition = 49;


  static async getDashboardCards(registrationCode, idExamSession) {
    let cards = this.getFixedCards(registrationCode, idExamSession);

    try {

      // verify dynamic links 
      const result = await courseService.perfezionamentoActiveLink(registrationCode);
      if (result.data.payload) {
        const { payload } = result.data;

        if (payload.examReservationEnabled === '1') {
          cards.push({
            title: 'Prenotazione Esami',
            link: ROUTES.COURSE_RC30_EXAM_RESERVATION,
            img: '/img/icon/badge.png',
            className: 'image-box'
          });
        }

        if (payload.enableExamSubject === '1') {
          cards.push({
            title: 'Argomenti Esami',
            link: ROUTES.COURSE_RC30_EXAM_SUBJECT,
            img: '/img/icon/7.png',
            className: 'image-box'
          });
        }

        if (payload.certRequestEnabled === '1') {

          cards.push({
            title: 'Consultazione Esito Finale',
            link: ROUTES.COURSE_RC30_CERT_REQUEST,
            img: '/img/icon/1.png',
            className: 'image-box'
          });
        }

        if (payload.activeSurvey && payload.activeSurvey.length > 0) {
          cards.push({
            title: 'Indagine ' + payload.activeSurvey,
            link: ROUTES.COURSE_SURVEY_MAIN + '/' + payload.activeSurvey,
            img: '/img/icon/call.png',
            className: 'image-box'
          });
        }

        if (payload.activeSurvey && payload.activeSurvey.length > 0) {
          cards.push({
            title: 'Indagine ' + payload.activeSurvey,
            link: ROUTES.COURSE_SURVEY_MAIN + '/' + payload.activeSurvey,
            img: '/img/icon/call.png',
            className: 'image-box'
          });
        }
      }

    } catch (error) {

    }

    return cards;
  }

  static getFixedCards(registrationCode, idExamSession) {

    return [
      {
        title: "Messaggi",
        link: ROUTES.COURSE_TICKETS,
        img: "/img/icon/message.png",
        className: "image-box",
      },
      {
        title: 'Situazione pagamenti', link: ROUTES.MU_COURSE_PAYMENTS,
        img: '/img/icon/payment.png', className: 'image-box'
    },      
      {
        title: "Lezioni",
        link: ROUTES.COURSE_LESSONLIVE_SCHEDULE,
        img: "/img/icon/presentation.png",
        className: "image-box",
      },
    ];
  }

  static getDashboardNotices(idExamSession) {

    return [
      {
        title: ``,
        content: `<p>La ringraziamo per aver scelto di partecipare al Percorso 30 CFU (ex art.13 DPCM 4 agosto 2023) 
          riservato a chi è in possesso di una qualsiasi abilitazione all'insegnamento o specializzazione sul sostegno.
           Ricordiamo che le attività didattiche (in modalità sincrona) si svolgeranno nel prossimo Autunno e potrà liberamente 
           scegliere di frequentare le lezioni nei giorni infrasettimanali (orari pomeridiani) o nei weekend.<br/>
          L'esame finale del corso è previsto entro la fine del 2024 almeno nelle seguenti sedi:<br/>
          Agrigento, Ancona, Bari, Bologna, Cagliari, Caserta/Cassino, Catania, Catanzaro, Como, Cosenza, Firenze, Foggia, Genova, Grosseto, Lecce, Matera, Mazara del Vallo (TP), Messina, Milano, Napoli, Novara/Alessandria, Nuoro/Sassari, Padova/Venezia, Palermo, Perugia/Terni, Pescara, Pisa, Reggio di Calabria, Roma, Sciacca, Torino, Udine, Verona, Vibo Valentia e Vittoria (RG).
          <br/>Altre eventuali sedi saranno attivate successivamente.
        La invitiamo ad attendere nostre comunicazioni che verranno notificate sia tramite email che tramite sms dopo le vacanze estive.
        </p>`,
      },
    ];

  }
}
