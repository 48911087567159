import Axios from 'axios';
import { mnemoService, proxyApiMnemo } from '.';

export const certificationService = {

    send(aa, url, payload='') {

        const aaURL = aa.toString().slice(-2);
        const completeUrl = process.env.REACT_APP_CRM_URL.replace('[yearTag]', aaURL) + url;

        return Axios.get(completeUrl, payload);
    },

    isEnabled(aa, rCode) {

        const url = 'moodleElearning/isIcdlCertificateEnabled/rCode/'+rCode;        
        return this.send(aa, url)
    },
   
    gompGetDeclaration(orderNumber) {
        const payload = mnemoService.buildPayload('getDeclaration_v03', 'orderNumber', orderNumber);
        return proxyApiMnemo.get('gomp/?payload='+encodeURI(payload));
    }, 

    
}