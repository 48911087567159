import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import {
  BoxDanger,
  MnemoLoading,
  MSection,
  SupportoTecnicoLive,
  Title,
} from "components/misc";
import { CourseHelper, dateHelper, learningLiveService, learningService, networkErrorHelper } from "libs";
import { ROUTES } from "const";
import { Icon, Button } from "design-react-kit";

class CourseLessonLiveSchedule extends Component {

  // SAVERIO: PEZZA per mostrare testi differenti per edizioni differenti
  firstEdition = 35;
  secondEdition = 38;
  thirdEdition = 42;

  fourthEdition = 48; // RC30CFUART13
  secondEditionUM = 49; // UM30CFU*
  secondEditionCM = 54; // CM30CFU*

  state = {
    loading: true,
    ladingError: false,
    rCode: "",
    course: {},
    userCourse: {},
    lessonsStage1: [],
    liveClassesStage1: null,
    lessonsStage2: [],
    liveClassesStage2: null,
  };

  componentDidMount() {
    const { rCode } = this.props.match.params;
    this.setState({ rCode: rCode });

    this.loadUserCourse(rCode);
  }

  loadUserCourse(rCode) {

    learningService
      .courseDashboard(rCode)
      .then(({ data }) => {

        const { course, userCourse } = data.payload;
        this.setState({ course, userCourse });

        this.loadRemoteStage1(course.id);
        this.loadRemoteStage2(course.id);
      })
      .catch(error => {

        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }
      });
  }

  loadRemoteStage1(courseId) {
    learningLiveService
      .MyScheduleStage(courseId, 1)
      .then(({ data }) => {
        this.setState({
          loading: false,
          lessonsStage1: data.payload.lessonLiveSchedules,
          liveClassesStage1: data.payload.lessonLiveClass,
        });
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }

        this.setState({
          loading: false,
          ladingError: true,
        });
      });
  }

  loadRemoteStage2(courseId) {
    learningLiveService
      .MyScheduleStage(courseId, 2)
      .then(({ data }) => {
        this.setState({
          loading: false,
          lessonsStage2: data.payload.lessonLiveSchedules,
          liveClassesStage2: data.payload.lessonLiveClass,
        });
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }

        this.setState({
          loading: false,
          ladingError: true,
        });
      });
  }

  showLink(lesson) {
    return false;
    const now = new Date();
    const dateStart = `${dateHelper.toUSDate(lesson.date)} ${lesson.hourStart
      } GMT+0100`;
    const lessonDateTime = Date.parse(dateStart);
    const oneHourAgo = new Date(now.getTime() - 60 * 60 * 1000);

    if (lessonDateTime > oneHourAgo && lessonDateTime < now) {
      // Lesson is between the last one hour
      return true;
    } else {
      return false;
    }
  }

  goDetail = (idLessonLive) => {
    const { rCode } = this.state;
    window.location = `${ROUTES.COURSE_LESSONLIVE_DETAIL}/${rCode}/${idLessonLive}`;
  };

  openMeet = (link) => {
    window.location = link;
  };

  renderStage(subTitle, lessonsStage, stage, showTitle = true) {

    const label = (stage === 1) ? "Prima" : "Seconda";

    return (<MSection className="m-2">
      {showTitle && <h4> {label} fase del percorso {subTitle}</h4>}
      {lessonsStage.length > 0 ? (
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Data</th>
                <th scope="col">CFU</th>
                <th scope="col">SSD - Ambito</th>
                <th scope="col">Ora Inizio</th>
                <th scope="col">Docente</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {lessonsStage.map((lesson, i) => (
                <tr key={i}>
                  <td>{dateHelper.toITDate(lesson.date)}</td>
                  <td>{lesson.cfu}</td>
                  <td>{lesson.ssd}</td>
                  <td>{dateHelper.toShortTime(lesson.hourStart)}</td>
                  <td>{lesson.teacher}</td>
                  <td>
                    {this.showLink(lesson) && (
                      <Button
                        id="radio"
                        color="primary bg-dark m-1"
                        onClick={(e) => {
                          this.openMeet(lesson.idMeet);
                        }}>
                        Partecipa
                      </Button>
                    )}

                    <Button
                      id="radio"
                      color="primary bg-dark m-1"
                      onClick={(e) => {
                        this.goDetail(lesson.id);
                      }}>
                      Dettaglio
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <p>
         Il Calendario delle lezioni successive sarà disponibile prossimamente.
        </p>
      )}
    </MSection>)
  }

  render() {
    const {
      loading,
      loadingError,
      lessonsStage1,
      lessonsStage2,
      rCode,
      liveClassesStage1,
      liveClassesStage2,
      userCourse,
    } = this.state;

    if (loading) return <MnemoLoading></MnemoLoading>;
    if (loadingError)
      return (
        <BoxDanger className="m-5">
          Si è verificato un errore durante l'elaborazione della richiesta, se
          il problema persiste <SupportoTecnicoLive />
        </BoxDanger>
      );

    const isCFUAll = CourseHelper.isCFUAll(userCourse.courseCode) ? true : false;
    const subTitle1 = (liveClassesStage1 && liveClassesStage1.length > 0) ? liveClassesStage1[0].label : "";
    const subTitle2 = (liveClassesStage2 && liveClassesStage2.length > 0) ? liveClassesStage2[0].label : "";

    return (
      <>
        <NavLink
          to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`}
          className="mx-5 btn btn-outline-primary float-right">
          <Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO
        </NavLink>
        {(isCFUAll) ? (
          <Title>Attività telematiche in modalità sincrona</Title>
        ) : (
          <Title>Attività formativa</Title>
        )}


        {(userCourse.idExamSession === this.secondEdition) ? <>
          {/** stage2 grid view */}
          {this.renderStage(subTitle2, lessonsStage2, 1)}

          {/** stage1 grid view */}
          {this.renderStage(subTitle1, lessonsStage1, 2)}

        </> : <>
          {/** stage1 grid view */}
          {this.renderStage(subTitle1, lessonsStage1, 1, !isCFUAll)}

          {/** stage2 grid view */}
          {this.renderStage(subTitle2, lessonsStage2, 2, !isCFUAll)}

        </>}

        <SupportoTecnicoLive courseCode={userCourse.courseCode} />.
      </>
    );
  }
}

export default withRouter(CourseLessonLiveSchedule);
