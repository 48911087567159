import React, { Component } from "react";
import {PDFView } from "components/misc";
export class LessonFile extends Component {

    componentDidMount() {
        //console.log(this.props.lesson);
    }

    render() {

        const resource = this.props.lesson;
        const fileUrl=   process.env.REACT_APP_API_URL +'/learning/lesson-file/download/'+resource.id;
        
        return (
            <>                 
                <PDFView file={fileUrl} canDownload={true} />
            </>
        );
    }
}