/**
 * Mostra tabella delle QuizTtemptReview da revisionare
 */
import React, { Component } from 'react';
import { MnemoLoading, BoxDanger, Title } from 'components/misc';
import { networkErrorHelper, dateHelper } from "libs";

import { MTable } from "components/table";
import { Container } from 'design-react-kit';
import { ROUTES } from 'const';
import { revisionService } from 'libs/api/revisionService';

export class ReviewCurrent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rndKey: 0,
            payload: [],
            isRevisioned: false
        };
    }

    //--- ---

    tableConfig = {
        columns: [
            {
                id: 'name',
                Header: 'Nome',
                accessor: 'name',
            },
            {
                id: 'surname',
                Header: 'Cognome',
                accessor: 'surname',
            },
            {
                id: 'courseCode',
                Header: 'Codice Corso',
                accessor: 'courseCode',
            },
            {
                id: 'dateEnd',
                Header: 'Data Invio',
                accessor: row => { return (row.dateEnd) ? dateHelper.toITDate(row.dateEnd) : '' },
            },
            {
                id: 'numberModule',
                Header: 'Modulo',
                accessor: 'numberModule',
            },
            /*{
                id: 'answer',
                Header: 'Risposta',
                accessor: 'answer',
            }*/
        ],
        actions: [
            {
                id: 'detail',
                accessor: 'id',
                label: 'Dettagli',
                icon: 'it-file',
                className: 'MTable-actions',
                onClick: (e, value) => {
                    window.location.href = ROUTES.QUIZREVIEW_FORM + '/' + value;
                }
            },
        ]
    }

    //--- ---

    componentDidMount() {
        this.loadRemote(this.state.isRevisioned);
    }

    loadRemote(isRevisioned) {

        this.setState({ loading: true });

        revisionService.listQuizOpenAll(isRevisioned).then(({ data }) => {

            //--- start: prepare reactTable ---
            const items = [];
            Object.entries(data.payload.quizreview).forEach(([key, val]) => {
                items.push(val);
            });

            this.setState({
                loading: false,
                payload: items,
                rndKey: Math.floor((Math.random() * 1000) + 1)
            });
            //--- end: prepare reactTable ---
        })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    console.log(error);
                    networkErrorHelper.notify(error);
                } else {
                    this.setState({
                        payload: [],
                        rndKey: Math.floor((Math.random() * 1000) + 1)
                    })
                }

                this.setState({ loading: false });
            });
    }

    render() {

        const { loading } = this.state;

        return (
            <>
                <Title>Revisioni Correnti</Title>
                <hr />
                <section className="my-4">
                    {loading ? (<MnemoLoading></MnemoLoading>) : (
                        <Container>
                            <MTable
                                key={this.state.rndKey}
                                tableData={this.state.payload}
                                tableConfig={this.tableConfig}
                            />
                        </Container>
                    )}

                    {(this.state.payload.length === 0) && (<BoxDanger key="nrp-1" className="my-3">
                        <div className="small text-info">
                            Non ci sono dati da mostrare in database.
                        </div>
                    </BoxDanger>)}
                </section>
            </>);
    }

}