import React, { Component } from "react";
import { Row, Col } from "design-react-kit";

import { Link } from "react-router-dom";

import "./HomeNotices.css";
export class HomeNotices extends Component {
  render() {
    return (
      <>
        <Row>
          {/* dottorati di ricerca */}
          <Col sm={12} md={12} lg={4} className="notice-box-wrapper">
            <Link to="/dottorati-di-ricerca" className="notice-box">
              <img
                className="w-100"
                src="/img/home/dottorato-v3.jpg"
                alt="Dottorato di Ricerca"
              ></img>
              <div className="notice-box-content">
                <h2 className="notice-box-title">
                  <span>Dottorati di ricerca</span>
                </h2>
                <h3 className="notice-box-subtitle">
                  <span>Per accedere alla carriera Universitaria</span>
                </h3>
              </div>
            </Link>
          </Col>
          {/* ./end dottorati di ricerca */}

          {/* Corsi sostegno estero  */}
          <Col sm={12} md={12} lg={4} className="notice-box-wrapper">
            <a
              href="https://www.asuniver.it/corso-di-sostegno-allestero/"
              className="notice-box"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                className="w-100"
                src="/img/home/sostegno_spagna.jpeg"
                alt="Carta docenti come generare il bonu scuola per i corsi mnemosine"
              ></img>
              <div className="notice-box-content">
                <h2 className="notice-box-title">
                  <span>Corso di Sostegno all'estero</span>
                </h2>
                <h3 className="notice-box-subtitle">
                  <span>Università Statale</span>
                </h3>
              </div>
            </a>
          </Col>
          {/* ./end corsi sostegno estero */}

          {/* carta docenti  */}
          <Col sm={12} md={12} lg={4} className="notice-box-wrapper">
            <Link
              to="/come-generare-il-bonus-scuola-per-i-corsi-mnemosine"
              className="notice-box"
            >
              <img
                className="w-100"
                src="/img/home/carta-docenti-v3.jpg"
                alt="Carta docenti come generare il bonu scuola per i corsi mnemosine"
              ></img>
              <div className="notice-box-content">
                <h2 className="notice-box-title">
                  <span>Carta docenti</span>
                </h2>
                <h3 className="notice-box-subtitle">
                  <span>Come generare il bonus con Mnemosine</span>
                </h3>
              </div>
            </Link>
          </Col>
          {/* ./end carta docenti */}

          {/* Formazione continua  */}
          {/* <Col sm={12} md={6} lg={4} className="notice-box-wrapper">
                    <Link to='/corsi-mnemosine/FC' className="notice-box" >
                        <img className="w-100" src="/img/home/formazione-continua-v2.webp" alt="Carta docenti come generare il bonu scuola per i corsi mnemosine" ></img>
                        <div className="notice-box-content">
                            <h2 className="notice-box-title"><span>Formazione continua</span></h2>
                            <h3 className="notice-box-subtitle"><span>Valorizza il buono scuola con i nostri corsi</span></h3>
                        </div>
                    </Link>
                </Col> */}
          {/* ./end Formazione continua */}

          {/* ABA */}
          {/* <Col sm={12} md={6} lg={4} className="notice-box-wrapper">
                    <Link to='/corso-online/ABA' className="notice-box" >
                        <img className="w-100" src="/img/home/aba-v2.webp" alt="corsi ABA" ></img>
                        <div className="notice-box-content">
                            <h2 className="notice-box-title"><span>ABA</span></h2>
                            <h3 className="notice-box-subtitle"><span>Applied Behavior Analysis</span></h3>
                        </div>
                    </Link>
                </Col> */}
          {/* ./end ABA */}

          {/* Didattica delle emozioni */}
          {/* <Col sm={12} md={6} lg={4} className="notice-box-wrapper">
                    <Link to='/corso-online/TDIDEM22' className="notice-box">
                        <img className="w-100" src="/img/home/didatica-delle-emozioni.webp" alt="Didattica delle emozioni" ></img>
                        <div className="notice-box-content">
                            <h2 className="notice-box-title"><span>DIDATTICA DELLE EMOZIONI</span></h2>
                        </div>
                    </Link>
                </Col> */}
          {/* ./end Didattica delle emozioni */}

          {/* Preparazione al concorso */}
          {/* <Col sm={12} md={6} lg={4} className="notice-box-wrapper">
                    <Link to='/corsi-mnemosine/PR' className="notice-box">
                        <img className="w-100" src="/img/home/corso-di-preparazione-al-concorso.webp" alt="Corsi di preparazione al concorso per personale docente " ></img>
                        <div className="notice-box-content">
                            <h2 className="notice-box-title"><span>Corsi di preparazione al concorso</span></h2>
                            <h3 className="notice-box-subtitle"><span>per l'immissione in ruole del Personale Docente</span></h3>
                        </div>
                    </Link>
                </Col> */}

          {/* ./end Preparazione al concorso */}
        </Row>

        {/* <Row className="m-5">
                <Col sm={4}>
                    <Link to='/come-generare-il-bonus-scuola-per-i-corsi-mnemosine' className="notice-link" >
                        <div className="notice-box-gray">
                            <svg className="notice-box-image" preserveAspectRatio="xMidYMid meet" data-bbox="1.438 1 81.176 51.036" xmlns="http://www.w3.org/2000/svg" viewBox="1.438 1 81.176 51.036" role="presentation" aria-hidden="true">
                                <g>
                                    <path d="M82.4 1.9c-.2-.6-.8-.9-1.4-.9L2.9 3.6c-.7 0-1.2.4-1.4 1.1-.2.6.1 1.2.7 1.6l18.6 11.2-8 13.2c-.3.5-.3 1.1 0 1.6s.7.7 1.4.7l13.8-.9 4.4 18.8c.1.5.5 1 1.1 1.1.5.1 1.1 0 1.5-.4l47.3-48c.4-.6.4-1.1.1-1.7zM8.1 6.4l63.6-2.1-47.6 11.9c-.1-.2-.3-.3-.5-.5L8.1 6.4zm8.8 23.4l7.3-12c.1-.2.2-.4.2-.6L77 4.2 30.1 29.4c-.3-.2-.6-.4-1-.4H29l-12.1.8zm17.7 17.7l-4-17.3L75.4 6.1 34.6 47.5z"></path>
                                </g>
                            </svg>
                            <br />
                            <span className="notice-link"><i>Carta del docente.<br />
                                Come generare il bonus con<br />Mnemosine</i></span>
                        </div>
                    </Link>
                </Col>
                <Col sm={4}>
                    <Link to='/corsi-mnemosine/FC'  className="notice-link">
                        <div className="notice-box-green">
                            <svg className="notice-box-image" preserveAspectRatio="xMidYMid meet" data-bbox="51 2.96 141 193.94" xmlns="http://www.w3.org/2000/svg" viewBox="51 2.96 141 193.94" role="presentation" aria-hidden="true">
                                <g>
                                    <path d="M136.1 58.9c.8 0 1.4.7 1.4 1.4V184c0 .8-.7 1.4-1.4 1.4H63.9c-.8 0-1.4-.7-1.4-1.4V60.4c0-.8.7-1.4 1.4-1.4h72.2m0-11.6H63.9c-7.1 0-12.9 5.8-12.9 12.9V184c0 7.1 5.8 12.9 12.9 12.9h72.2c7.1 0 12.9-5.8 12.9-12.9V60.4c0-7.2-5.8-13-12.9-13z"></path>
                                    <path d="M164.8 54.3c-2.9 0-5.4-2.2-5.7-5.2-.6-6.7-5.7-12.6-12.3-14.3-3.1-.8-4.9-4-4.1-7s4-4.9 7-4.1c11.2 2.9 19.7 13 20.8 24.3.3 3.2-2 6-5.2 6.3h-.5z"></path>
                                    <path d="M186.2 53.1c-3.2 0-5.7-2.6-5.8-5.7 0-15.4-11.5-29.6-26.7-33-3.1-.7-5.1-3.8-4.4-6.9s3.8-5.1 6.9-4.4C176.6 7.6 192 26.6 192 47.3c0 3.2-2.6 5.8-5.8 5.8.1 0 0 0 0 0z"></path>
                                    <path d="M109.7 165c0 5.357-4.343 9.7-9.7 9.7-5.357 0-9.7-4.343-9.7-9.7 0-5.357 4.343-9.7 9.7-9.7 5.357 0 9.7 4.343 9.7 9.7z"></path>
                                </g>
                            </svg>
                            <br />
                            <span className="notice-link-2"><i>Formazione Continua<br/>Valorizza il buono scuola<br />
                            con i nostri corsi</i></span>
                        </div>
                    </Link>
                </Col>
                <Col sm={4}>
                    <Link to='/corsi-mnemosine/PR'  className="notice-link">
                        <div className="notice-box-green-2">

                            <svg className="notice-box-image" preserveAspectRatio="xMidYMid meet" data-bbox="51 2.96 141 193.94" xmlns="http://www.w3.org/2000/svg" viewBox="51 2.96 141 193.94" role="presentation" aria-hidden="true">
                                <g>
                                    <path d="M136.1 58.9c.8 0 1.4.7 1.4 1.4V184c0 .8-.7 1.4-1.4 1.4H63.9c-.8 0-1.4-.7-1.4-1.4V60.4c0-.8.7-1.4 1.4-1.4h72.2m0-11.6H63.9c-7.1 0-12.9 5.8-12.9 12.9V184c0 7.1 5.8 12.9 12.9 12.9h72.2c7.1 0 12.9-5.8 12.9-12.9V60.4c0-7.2-5.8-13-12.9-13z"></path>
                                    <path d="M164.8 54.3c-2.9 0-5.4-2.2-5.7-5.2-.6-6.7-5.7-12.6-12.3-14.3-3.1-.8-4.9-4-4.1-7s4-4.9 7-4.1c11.2 2.9 19.7 13 20.8 24.3.3 3.2-2 6-5.2 6.3h-.5z"></path>
                                    <path d="M186.2 53.1c-3.2 0-5.7-2.6-5.8-5.7 0-15.4-11.5-29.6-26.7-33-3.1-.7-5.1-3.8-4.4-6.9s3.8-5.1 6.9-4.4C176.6 7.6 192 26.6 192 47.3c0 3.2-2.6 5.8-5.8 5.8.1 0 0 0 0 0z"></path>
                                    <path d="M109.7 165c0 5.357-4.343 9.7-9.7 9.7-5.357 0-9.7-4.343-9.7-9.7 0-5.357 4.343-9.7 9.7-9.7 5.357 0 9.7 4.343 9.7 9.7z"></path>
                                </g>
                            </svg>
                            <br />
                            <h6 className="notice-text">
                                <span className="notice-link-2"><i>Corsi di Preparazione al Concorso<br />
                                per l'immissione in ruolo del <br />
                                Personale Docente</i></span>
                            </h6>
                        </div>
                    </Link>
                </Col>
            </Row> */}
      </>
    );
  }
}
