import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardSignature,
  CardFooter,
  CardCategory,
} from "design-react-kit";
import { Link } from "react-router-dom";

export class MnemoCardLink extends Component {
  openLink(link) {}

  render() {
    const {
      title,
      content,
      date,
      signature,
      actionLabel,
      tag,
      actionLink,
      actionParam,
      category,
    } = this.props;

    let to = actionLink;
    if (actionParam) {
      to += `/${actionParam}`;
    }

    return (
      <>
        <article className="card-wrapper card-space">
          <Card noWrapper className="card-bg rounded shadow">
            <CardBody>
              <CardCategory date={date}>{category}</CardCategory>
              {title && (
                <CardTitle tag="h5"> {ReactHtmlParser(title)}</CardTitle>
              )}
              <CardText>{content}</CardText>
              {signature && <CardSignature>{signature}</CardSignature>}
              <CardFooter>
                {/* <a className="link-corsi-laurea float-right wrapper-link btn btn-primary bg-dark" href={to} target="_blank" rel="noopener noreferrer"> */}
                <a
                  className={`${
                    tag ? tag : ""
                  } float-right wrapper-link btn btn-primary bg-dark`}
                  href={to}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {actionLabel}
                </a>
              </CardFooter>
            </CardBody>
          </Card>
        </article>
      </>
    );
  }
}
