
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { CourseHelper, courseService, networkErrorHelper } from "libs";
import ReactHtmlParser from 'react-html-parser';

import { CourseShopper } from "components/enrollment";
import { BoxDanger, BoxInfo, MnemoLoading, SupportoTecnico } from "components/misc";
import { Helmet } from "react-helmet";

class CourseLandingPageFullLayout extends Component {

    state = {
        courseCode: null,
        loading: true,
        loadingError: false,
        course: null,
    }

    componentDidMount() {

        let { courseCode } = this.props.match.params;
        this.setState({ courseCode });
        this.loadRemote(courseCode);
    }

    loadRemote(courseCode) {

        // load courseContainer from BE
        const currentAA = CourseHelper.getCurrentAA(courseCode); // parseInt(process.env.REACT_APP_AA_YEAR);

        courseService
            .getByCodeAndAA(courseCode, currentAA)
            .then(({ data }) => {

                const { course } = data.payload;
                this.setState({
                    course,
                    loading: false,
                    loadingError: false
                });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
                console.log(error);
                this.setState({ loadingError: true, loading: false });
            });
    }

    render() {

        const { isLoggedIn } = this.props;
        const { loading, loadingError, course, courseCode } = this.state;

        if (loading) return (<MnemoLoading></MnemoLoading>);
        if (loadingError) return (<BoxDanger>Si è verificato un errore durante
            il caricamento dei dati del corso, se il problema persiste puoi <SupportoTecnico /></BoxDanger>);

        return (
            <>
                <div>
                    <Helmet>
                        <title>{course ? course.title : courseCode}</title>
                        <meta property="og:title" content={course.title} />
                    </Helmet>
                </div>
                <h3 className="p-3">{ReactHtmlParser(course.title)}</h3>
                {(!course.courseHidden) ? (<>
                    <div className="m-3 mt-5 m-lg-5 m-sm-3">
                        <>{ReactHtmlParser(course.summary)}</>
                    </div>
                    {(course.enrollEnabled) &&
                        (<section name="enroll_section" id="enroll_section" className="course_section">

                            <CourseShopper
                                isLoggedIn={isLoggedIn}
                                enrollManifesto={course.enrollManifesto}
                                courseCode={course.code}
                                plugin={course.plugin}
                            >
                            </CourseShopper>
                        </section>)}
                </>) : (
                    <section>
                        <BoxInfo>Corso non attivo.</BoxInfo>
                    </section>
                )}
            </>
        );
    }
}

export default withRouter(CourseLandingPageFullLayout);